import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getContractTypesByTenantId } from "../../networking/apiEndpoints";
import { ContractTypeSummary } from "../../types/components/contractTypeModel";


interface ResponseModelState {
  status: boolean; 
  message: string; 
  result: ContractTypeSummary[] | undefined; 
  responseStatus: number; 
}

const initialState: ResponseModelState = {
  result: [], 
  status: false, 
  message: "", 
  responseStatus: 200, 
};


export const getContractTypesByTenantIdApi = createAsyncThunk<
  AxiosResponse,
  void,
  {
    rejectValue: AxiosResponse;
  }
>(
  "get-contract-types/get",
  async (_arg, thunkApi) => {
    var tenantId = localStorage.getItem("tenantId");
    const response = await axios.get(getContractTypesByTenantId + '/' + tenantId, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      validateStatus: function(status) {
        return status < 500; 
      },
    });

    if (response.status === 401) {
      return thunkApi.rejectWithValue(response);
    }
    return response;
  }
);


export const getContractTypesByTenantIdSlice = createSlice({
  name: "getContractTypesByTenantId",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContractTypesByTenantIdApi.fulfilled, (state, action) => {
        state.result = action.payload.data.result;  
        state.status = action.payload.data.status;  
        state.message = action.payload.data.message;
        state.responseStatus = action.payload.status;
      })
      .addCase(getContractTypesByTenantIdApi.rejected, (state, action) => {
        state.status = false;
        if (action.payload !== undefined) {
          state.responseStatus = action.payload.status;
        }
      });
  },
});

export default getContractTypesByTenantIdSlice.reducer;
