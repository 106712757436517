import React from 'react';
import AddDesignation from '../../../components/Staffing/DesignationList/AddDesignation';
import { useParams } from 'react-router-dom';

const EditDesignation :React.FC<{}> = ({ }) => {
  const {id} = useParams();  
  return (
    <div>
    <AddDesignation mode={'edit'} itemId={id}/>
    </div>
  );
};
export default EditDesignation; 