import React, { useState, useEffect } from "react";
import { HeadCell } from "../../../../types/components/contractTypeModel";
import EnhancedTable from "../../../cst/ViewTable/ListViewTable";
import { defaultPageNumber, DEPARTMENT_NAME } from "../../../../utilities/Constants";
import { EmailTemplate } from "../../../../types/components/rass/emailTemplateModal";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import { getEmailTemplateListApi } from "../../../../redux/features/rras/getEmailTemplateListSlice";
import Handle401Status from "../../../../types/components/handleApiReasponseStatus";
import { CircularProgress, circularProgressClasses } from "@mui/material";

interface TemplateProps {
  onRadioSelect?: (selectedId: string) => void;  
  externalSelectedRadioId?: string | null;
}

const SelectTemplates: React.FC<TemplateProps> = ({onRadioSelect, externalSelectedRadioId}) => { 
  var selectedTenantId = localStorage.getItem("tenantId");  
  const Dispatch = useAppDispatch();
    const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState<string>(localStorage.getItem(window.location + "RowsPerPage") ?? defaultPageNumber );
  const { emailTemplateListResponse, loading } = useAppSelector(
    (state) => state.getEmailTemplateList
  );    
  const [isloading, setIsloading] = useState(false);  
  const [result, setResult] = useState(emailTemplateListResponse);
  const storedData = sessionStorage.getItem('finalMeetingData');

  const handleRadioSelect = (selectedId: string, isSelected: boolean) => {

    let finalMeetingData = {
      contactName: "",
      companyName: "",
      transcript: "",
      template: ""
    };
  
    if (storedData) {
      try {
        finalMeetingData = JSON.parse(storedData).finalMeetingData || finalMeetingData;
      } catch (err) {
        console.error("Error parsing session storage data", err);
      }
    }
    finalMeetingData.template = typeof selectedId === "string" ? selectedId : JSON.stringify(selectedId);
    sessionStorage.setItem('finalMeetingData', JSON.stringify({ finalMeetingData }));
    if (onRadioSelect) {
      onRadioSelect(selectedId);
    }
  }
  const headCells: HeadCell<string>[] = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "id",
      visible: false,
      className: "id-cell",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Template Name",
      sort: true,
      viewAction: true,
      className: "contract-title",
    },
    {
      id: "LinkedSummaryTemplate",
      numeric: false,
      disablePadding: false,
      label: "Linked Summary Template",
      sort: true,
      className: "contract-title",
      viewAction: true,
  },
  {
      id: "DepartmentName",
      numeric: false,
      disablePadding: false,
      label: DEPARTMENT_NAME,
      sort: true,
      className: "contract-title",
      viewAction: true,
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "Template Type",
      sort: true,
      className: "contract-title",
      viewAction: true,
    }
  ];

  useEffect(() => {
    const filterData: EmailTemplate = {
      id: "",
      tenantId: selectedTenantId!,
      name: "",
      idSummaryTemplate: "",
    };
    Dispatch(getEmailTemplateListApi(filterData)).then(
      (getEmailTemplatesResponse) => {
        setResult(getEmailTemplatesResponse.payload);
        setIsloading(true);
        if (getEmailTemplatesResponse.payload?.status === 401) {
          Handle401Status(navigate, window);
        }
      }
    );
  }, [])

  return (
    <div className="calendar-wrapper step3">
      <h2>Select Templates</h2>
      <div className="scroll-div">
      {loading && (
        <div className="loader-center">
          <CircularProgress
            classes={{ root: circularProgressClasses.root }}
          ></CircularProgress>
        </div>
      )}
      {!loading && isloading && (
        <EnhancedTable
          Data={result}
          column={headCells}
          enableCheckBox={false}
          enableRadioButton={false}
          enableRadioButtonTemplate={true}
          rowsPerTable={parseInt(rowsPerPage)}
          handleRadioSelectTemplate={handleRadioSelect}
          externalSelectedRadioId={externalSelectedRadioId}
        />
      )}
      </div>
    </div>
  )
}

export default SelectTemplates;