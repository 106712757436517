import { RouteObject } from "react-router-dom";
import React, { lazy } from "react";
import {
  PathEmployeeList,
  PathEmployeeSkills,
  PathDesignationList,
  PathBillingDesignationList,
  PathPortFolioCompanyList,
  PathEmployeeProjectList,
  PathEditDesignation,
  PathAddDesignation,
  PathEditPortFolioCompany,
  PathAddPortFolioCompany,
  PathAddBillingDesignation,
  PathEditBillingDesignation,
  PathEditEmployeeSkill,
  PathAddEmployeeSkill,
} from "../utilities/Constants";
import EditDesignation from "../components/Staffing/DesignationList/EditDesignation";
import AddDesignation from "../components/Staffing/DesignationList/AddDesignation";
import EditBillingDesignation from "../components/Staffing/BillingDesignationList/EditBillingDesignation";
import AddBillingDesignation from "../components/Staffing/BillingDesignationList/AddBillingDesignation";
import EditPortFolioCompany from "../components/Staffing/PortFolioCompanyList/EditPortFolioCompany";
import AddPortFolioCompany from "../components/Staffing/PortFolioCompanyList/AddPortFolioCompany";
import EditEmployeeSkill from "../components/Staffing/EmployeeSkillList/EditEmployeeSkill";
import AddEmployeeSkill from "../components/Staffing/EmployeeSkillList/AddEmployeeSkill";
const EmployeeList = lazy(() => import("../pages/Staffing/EmployeeList"));
const EmployeeSkills = lazy(
  () => import("../components/Staffing/EmployeeSkillList/EmployeeSkills")
);

const DesignationList = lazy(() => import("../pages/Staffing/DesignationList"));
const BillingDesignationList = lazy(() => import("../pages/Staffing/BillingDesignationList"));
const PortFolioCompanyList = lazy(() => import("../pages/Staffing/PortFolioCompanyList"));
const EmployeeProjectList = lazy(() => import("../pages/Staffing/EmployeeProjectList"));


const staffingRoutes: RouteObject[] = [
  { path: PathEmployeeList, element: <EmployeeList /> },
  { path: PathEmployeeSkills, element: <EmployeeSkills /> },
  { path: PathDesignationList, element: <DesignationList /> },
  { path: PathBillingDesignationList, element: <BillingDesignationList /> },
  { path: PathPortFolioCompanyList, element: <PortFolioCompanyList /> },
  { path: PathEmployeeProjectList, element: <EmployeeProjectList /> },
  {
    path: `${PathEditDesignation}/:id`,
    Component: () => <EditDesignation></EditDesignation>,
  },
  {
    path: PathAddDesignation,
    Component: () => (
      <AddDesignation
        mode="add"
        itemId={""}
      ></AddDesignation>
    ),
  },
  {
    path: `${PathEditPortFolioCompany}/:id`,
    Component: () => <EditPortFolioCompany></EditPortFolioCompany>,
  },
  {
    path: PathAddPortFolioCompany,
    Component: () => (
      <AddPortFolioCompany
        mode="add"
        itemId={""}
      ></AddPortFolioCompany>
    ),
  },
  {
    path: PathAddBillingDesignation,
    Component: () => (
      <AddBillingDesignation
        mode="add"
        itemId={""}
      ></AddBillingDesignation>
    ),
  },
  {
    path: `${PathEditBillingDesignation}/:id`,
    Component: () => <EditBillingDesignation></EditBillingDesignation>,
  },
 
  {
    path: PathAddEmployeeSkill,
    Component: () => (
      <AddEmployeeSkill
        mode="add"
        itemId={""}
      ></AddEmployeeSkill>
    ),
  },
  {
    path: `${PathEditEmployeeSkill}/:id`,
    Component: () => <EditEmployeeSkill></EditEmployeeSkill>,
  },
];


export default staffingRoutes;
