export const DocumentNameErrorText = "Document Name is required.";
export const CancelDialogMessage =
  "Are you sure you do not want to upload the selected file(s)?";
export const DiscardChangesDialogMessage =
  "Are you sure you want to discard the changes without saving ?";
export const SummaryTemplateAddCancelDialogMessage =
  "Your changes will be lost. Are you sure you want to exit?";
export const MAX_FILE_SIZE = 25600;
export const FileUploadMsg = "File upload successfully.";
export const FileUploadErrorMsg = "File size limit exceeded.";
export const FileSizeText = "Only select PDF files";
export const DeleteContractDialogMessage =
  "Are you sure you want to delete this contract?";
export const DeleteMultipleContractDialogMessage =
  "Are you sure you want to delete the selected contract(s)?";
export const DeleteRecordSuccessMessage = "Record deleted successfully.";
export const ScreeningTermsDeleteSuccessfully = "Screening Term(s) deleted successfully";
export const ScreeningTermsNotDeleteSuccessfully = "Screening Term(s) not deleted successfully";
export const ScreeningTermPopupMessage = "Are you sure you want to delete the Screening Term(s)?"
export const FailedDuplicateCheck = "Failed to check duplicate contract type";
export const ContractTypeExists = "Contract type already exists";
export const FailToSaveContractType = "Failed to save contract type";
export const FailToSaveDesignation = "Failed to save designation";
export const FailToSaveBillingDesignation = "Failed to save billing designation";
export const SaveContractType = "Contract type saved successfully";
export const FailToSavePortFolioCompany = "Failed to save portfoliocompany";
export const FailToSaveEmployeeSkill = "Failed to save employeeskill";
export const RequiredFields = "This field is required";
export const EditContractSavedMsg = "Contract saved successfully";
export const EditContractSavedFailedMsg = "Failed to save contract";
export const EditContractCreatedMsg = "Contract created successfully";
export const EditContractFailedMsg = "Failed to create contract";
export const DesignationExists = "Designation already exists";
export const BillingDesignationExists = "Billing Designation already exists";
export const AlphaNumericWithSpace = "Value must be alphanumeric and can include spaces";
export const MoveContractsTitle = "Move to Contract Type"
export const MoveContractMessage = "Select the contract type in which you want to move selected Contract"
export const DropDownDefaultText = "Select"
export const PortFolioCompanyExists = "PortFolioCompany already exists";
export const EmployeeSkillExists = "EmployeeSkill already exists";
//export const Method = [{ 'id':'EgnyteAPI' , 'name' :'EgnyteAPI' },{ 'id':'Claude' , 'name' :'AWS Claude' }]
export const ContractTermPopupMessage = "Are you sure you want to delete these Contract Term(s)? Associated Contract Types will be impacted."
export const DeleteContractTermSuccessMessage = "Contract Term(s) deleted successfully.";
export const Operator = [
  { id: "Contains", name: "Contains" },
  { id: "NotContains", name: "Does not Contain" },
  { id: "Equal", name: "= (equal to)" },
  { id: "NotEqual", name: "!= (not equal to)" },
  { id: "GreaterThan", name: "> (greater than)" },
  { id: "GreaterThanEqual", name: ">= (greater than equal to)" },
  { id: "LessThan", name: "< (less than)" },
  { id: "LessThanEqual", name: "<= (less than equal to)" },
];
export const DataType = [
  { id: "Boolean", name: "Boolean" },
  { id: "DateTime", name: "DateTime" },
  { id: "Integer", name: "Integer" },
  { id: "String", name: "String" },
];
export const YesNo = [
  { id: "true", name: "Yes" },
  { id: "false", name: "No" },
];
export const SummaryTemplate = [
  { id: "SummaryTemplate", name: "Summary Template 1" },
];
export const TemplateDepartment = [
  { id: "TemplateDepartment", name: "Template Department 1" },
];
export const FailToSaveContractTerm = "Failed to save contract term";
export const IsContractTermExists =
  "Contract term already exists with same term name & contract type.";
export const SaveContractTerm = "Contract term saved successfully";
export const DeleteContractTypeSuccessMessage =
  "Contract Type has been deleted.";
export const DeleteContractTypeErrorMessage = "Failed to delete Contract Type.";
export const MaxFileSelectMsg =
  "Maximum of hundred[100] contracts can be selected.";
export const FileType = "application/pdf";
export const DeleteContractTermDialogMessage =
  "<p>Are you sure you want to delete the Contract Term <span>&quot;{contract_term_name}&quot;</span> ? Associated Contract Type <span>&quot;{contract_type_name}&quot;</span> will be impacted.</p>";
export const DeleteContractTermFailMessage = "Fail to deleted contract term.";
export const DefaultActiveMenu = "ContractManagement";
export const FileSizeError =
  "Request Files Too Large or Contract Name already exists";
export const feedbackAdd = "Feedback added successfully";
export const AddNewUserMsg = "New user added successfully";
export const FromDateFutureValidationMsg = "From Date cannot be a future date.";
export const ToDateFutureValidationMsg = "To Date cannot be a future date.";
export const FromDateGreaterThen =
  "From Date should always be less than or equal to To Date.";
export const ToDateGreaterThen =
  "To Date should always be greater than or equal to From Date.";
export const regExEmail = /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?$/; 
export const regExUserName = /^[a-zA-Z0-9._%+@-]{1,255}$/; 
export const forgotPasswordSucess = "Password reset email sent successfully.";
export const forgotPasswordFail = "Password reset fail.";
export const passwordNotMatch = "Password does not match.";
export const resetPasswordFail = "Fail to reset the password.";
export const resetPasswordExpired = "Failed to reset password. Token expired.";
export const userProfileMenu = ["My Profile", "Change Password"];
export const passwordChangeSucess = "Password changed successfully."
export const superUserRoleName = "Super User";
export const userRoleName = "User";
export const systemAdminRoleName = "System Administrator";
export const adminRoleName = "Administrator";
export const ReanalyzeError = "Error Processing Reanalyze.";
export const ContractStatus = [
  { id: "Completed", name: "Completed" },
  { id: "Failed", name: "Failed" },
  { id: "InProgress", name: "In Progress" },
  { id: "Reanalyze", name: "Reanalyze" },
];
export const PreProcessingContractStatus = [
  { id: "Completed", name: "Completed" },
  { id: "Failed", name: "Failed" },
  { id: "FailedUpload", name: "Failed Upload" },
  { id: "InProgress", name: "In Progress" },
  { id: "Oversized", name: "Failed Oversized" },
];
export const AllowedStatusesForReanalyze = ["ReAnalyze", "Failed","Completed"];
export const AllowedCompletedStatus = ["Completed"];
export const AllowedReanalyzeStatus = ["ReAnalyze", "Failed", "Completed"];
export const AllowedRoleStatus = ["System Administrator"];
export const OnlyNumericalsAllowed =
  "Only numerical characters (between 1 to 999) will be allowed.";
export const OnlyNumericalsWithTwoDecimals =
  "Enter only numerical characters and decimals up to two places (0.00 - 999999.99).";
export const DuplicateOrderNo =
  "Order # for the selected contract type already exists. Use a different order #";
export const AttachChildContract = "Contracts attached successfully.";
export const AddChildContractFail = "Fail to add the child contract.";
export const NoAttachments = "No Contract attachments found";
export const DeleteContractWithChildDialogMessage =
  "Deleting will disassociate all contracts linked to it. Are you sure you want to delete the contract?";
export const DeleteChildContractDialogMessage =
  "Removing will disassociate it from the main contract. Are you sure you want to remove the linked contract?";
export const RemovedContractSucessfully = "Contracts removed successfully";
export const FailToRemovedContract = "Fail to remove contract";
export const FailToSetPrecedence = "Fail to set precedence";
export const ChildContractPrecedenceSetSucessfully =
  "Precedence has been set successfully";
export const SourceTextToDisplayInContractTerms =
  'Also extract Source as "Source:" from where the entity is extracted with paragarah and page number.';
export const FailToSaveFollowUpEmail = "Failed to save follow-up email";
export const SaveFollowUpEmail = "Summary and email are saved successfully.";
export const SourceTextToDisplayInContractTermsForGemini =
  'Also extract Source as "**Source:**" from where the entity is extracted with paragarah and page number where all source to display under one heading.';
export const NotSuccessfull = "Not Successfull, ";
export const DuplicateContractTerm = "already exists";
export const InvalidOrderNo = "Invalid Order #. Cannot execute the rule.";
export const ColumnNameType = "TYPE";
export const EmailConstants = {
  contentSummary: "Content Summary",
  generatedEmail: "Generated Email",
  createEmail: "Create Email & Summary",
  viewEmail: "View Email & Summary",
  emailVersion: "Email Version",
  previewTemplates: "Preview Templates",
  emailTemplateText: "Email Template Text",
  summaryTemplateText: "Summary Template Text",  
};
export const MessageComponentConstants = {
  viewMessagePageTitle : "View Message",
  editMessagePageitle : "Edit Message",
  personaTitleName : "Persona Title",
  versionTypeAI    : "AI generated",
  richTextBoxTitle : "Generated Message",
  regenerateDialogMessage : "This will overwrite the message content. Would you like to continue?",
  errorMessage : "Failed to generate message." ,
  generatingText : "Generating" ,
  regeneratedText : "Regenerate" ,
  deleteMessageErrorText : "Failed to delete Message.",
  deleteMessageSuccessText : "Message deleted successfully.",
  deleteMessageConfirmationText: "Are you sure you want to delete the Message(s)?"

}
export const CreateEmailConstants = {
  useSummaryInsteadTranscript: "Use Summary instead of Transcript",
  generateSummary: "Generate Summary only",
};
export const ValidateOrderNo =
  "Set Order # cannot be less than or equal to Execute and Else order numbers.";
export const RegenerateDialogMessage =
  "This will overwrite the current summary/email content. Would you like to continue?";
export const DisclaimerCSVReportMessage =
  "Disclaimer!  Do not rely on this report or any other output produced by this system as a substitute for legal advice. This report is for informational purposes only.";

export const VersionTypeAIGenerated = "AI generated";
export const VersionTypeManual = "Manual";
export const FollowUpEmailCreator = "Meeting Summary & Email";
export const RolesAndPermissions = "Roles & Permissions";
export const UserList = "User List";
export const PreProcessing = "Pre-Processing Contracts"
export const Contracts = "Contracts";
export const Success = "success";
export const MoveSuccessMessage = "Contract Moved Successfully";
export const MoveFailedMessage = "Failed to move contract";
export const GenericError = "An error occurred"
export const ReanalyzeSuccessMessage = "Contract Reanalyzed Successfully";

export const Error = "error";
export const SummaryTemplateClonedSuccessfully =
  "Summary Template Cloned Successfully";
export const SummaryTemplateSavedSuccessfully =
  "Summary Template Saved Successfully";
export const defaultPageNumber = "25";
export const AddSummaryTemp = "Add Summary Template";
export const AddSummaryTempBtnText = "Summary Template";
export const AddEmailTempBtnText = "Email Template";
export const EditSummaryTemp = "Edit Summary Template";
export const ViewSummaryTemp = "View Summary Template";
export const StandardSummaryTemp = "Standard Summary Template";
export const MAX_NAME_LENGTH = 100;
export const MAX_TEXT_LENGTH = 50000;
export const MAX_LENGTH_PURPOSEOFMESSAGE = 1000;
export const DEFAULT_HIDEOUT_TIME = 6000;
export const NAME_FIELD_LABEL = "Template Name *";
export const TEXT_FIELD_LABEL = "Content *";
export const EDIT_EMAIL_SUMMARY = "Edit Email & Summary";
export const DEPARTMENT_NAME = "Department Name";
export const DeleteEmailFollowUpMessage ="Deleting the selected meeting(s) will also delete previous meeting history. Are you sure you want to delete the meeting(s)?"
export const DeleteEmailFollowUpSuccessMessage =
  "Meeting(s) deleted successfully.";
export const DeleteCustomEmailTemplateSuccessMessage =
  "Custom Email Template has been deleted successfully.";
export const DeleteEmailFollowUpErrorMessage = "Failed to delete FollowUp email.";
export const DeleteCustomEmailTemplateErrorMessage = "Failed to delete Custom email template.";
export const NotDeleteStandardTemplateMessage = "You cannot delete Standard Email Templates.";
export const AssociatedEmailTemplateMessage = "This email template is associated with follow-up email(s). Remove the association and then try to delete the email template.";
export const EmailTemplateClonedSuccessfully = "Email Template and Sales Training Prompts cloned successfully.";
export const EmailTemplatePopUpMessage = "Are you sure you want to delete this email template?";
export const CancelDialogEmailTemplate = "Your changes will be lost. Are you sure you want to exit?";
export const FailToSaveEmailTemplate = "Failed to save email template";
export const FailToSaveSalesTrainingEmailTemplate = "Failed to save Sales Training Prompts Email Template.";
export const SaveEmailTemplate = "Email template saved successfully.";
export const SaveSalesTrainingEmailTemplate = "Prompts added to email template successfully.";
export const SaveEmailTemplateToSalesTrainingPrompt = "Email Template added to the prompt successfully.";
export const RestrictSaveSalesTrainingEmailTemplate = "You cannot add Sales Training Prompts without first saving Email Template information.";
export const RestrictSaveEmailTemplatesInSalesPrompt = "You cannot add Email Templates without first saving Sales Training Prompt information.";
export const AddEmailTemplateHeading = "Add Email Template";
export const EditEmailTemplateHeading = "Edit Email Template";
export const ViewEmailTemplateHeading = "View Email Template";
export const StandardEmailTemp = "Standard Email Template";
export const CustomEmailTemplateType = "Custom";
export const SystemAdminNameUsedInCreateRole = "System Administrator role cannot be created";
export const RoleNameErrorText = "Role Name is required.";
export const regExAlphanumeric =/^[a-zA-Z0-9\s]+$/;
export const InvalidRoleId = "Invalid RoleId!";
export const GeneratedEmail = "Would you also like to generate email?";
export const AiTextWarning = "This email version is AI generated. You must edit the generated email draft before sending.";
export const ContentCopied = "Content Copied Successfully"
export const RoleCancelDialogMessage = "Your changes will be lost. Are you sure you want to cancel?";
export const UserPermissionsCancelDialogMessage =
  "Your changes will be lost. Are you sure you want to cancel?";
export const AssignedUsersCancelDialogMessage =
  "Your changes will be lost. Are you sure you want to exit?";
export const generateEmailLoaderWithText = "All generated text must be edited before sending externally"
export const DeleteSummaryTemplateMessage ="Are you sure you want to delete the Summary Template(s)?"
export const DeleteSummaryTemplateSuccessMessage =  "Summary Template deleted successfully.";
export const DeleteSummaryStandardTemplateMessage =  "You cannot delete Standard Summary Templates.";
export const DeleteSummaryTemplateErrorMessage =  "Error Deleting Summary Template.";
export const DeleteSummaryTemplateAssociatedWithEmailMessage =  "This summary template is associated with email template(s). Remove the association and then try to delete the summary template";
export const activityEvents: string[] = ['click','mousemove','mousedown','keydown','scroll','touchstart','touchmove','wheel','visibilitychange','focus','resize',];
export const RegModuleMessage = "When using the Riverside Engage Genius, you are required to edit all generated text before sending externally. Failure to comply may result in disciplinary action, up to and including termination of employment.";
export const DeleteRoleMessage = "Are you sure you want to delete the role <span>&quot;{role_name}&quot;</span> ?";
export const DeleteMultipleRolesMessage = "Are you sure you want to delete the Role(s)?"
export const RoleUnassignedToUser = "This role is assigned to users. You must first unassign the user(s) to delete this Role.";
export const PermissionUnassignedToUser = "Please select atleast one permission or unassigned all the users from this role.";
export const FollowUpMailsTooltipContent = "Search by Recipient Name / Recipient Company Name / Preview";
export const DaysToReDisplayDisclaimer = "90";
export const ImageUploadSuccessfully = "Image Uploaded Successfully!";
export const ImageDeleteSuccessfully = "Image Deleted Successfully!";
export const ImageFileNotUploaded = "Image file not uploaded";
export const MAX_SIZE = 800 * 1024; // 800 KB in bytes
export const ALLOWED_FORMATS = ['image/png', 'image/jpeg', 'image/jpg'];
export const INVALID_FILE_FORMAT_TEXT = "Invalid file format. Allowed formats: PNG, JPEG, JPG.";
export const INVALID_FILE_SIZE_TEXT = "File size exceeds 800 KB.";
export const INVALID_SQUARE_DIMENSION_TEXT = "Image width and height must be equal.";
export const INFO_TEXT_PROFILE_IMAGE = "Allowed JPG, JPEG or PNG format up to 800 KB";
export const MODULE_SYNCED_SUCCESSFULLY = "Module Synced Successfully";
export const Status = [{ id: "1", name: "Active" }, { id: "2", name: "InActive" }, { id: "3", name: "Locked" },];
export const ContractTypes = "Contract Types";
export const selectContractTypes = "Select Contract Types";
export const ContractTerms = "Contract Terms";
export const ScreeningTerms = "Screening Terms";
export const EmailTemplates = "Email Templates";
export const SummaryTemplates = "Summary Templates";
export const Messages = "Messages";
export const SalesTrainingInsights = "Sales Training Insights Report";
export const AIMessages = "AI Messages"
export const AddMessageText = "Create Message";
export const GenerateMessageCancelDialogMessage =
  "Are you sure you want to discard the changes?";
export const GenerateMessageSaveSuccessMessage =
  "Message generated successfully";
export const CharactersInstructions = "Ensure that the instructions you provide generate a meaningful response within 100 characters. Selecting this check box will use the generated response as a tag for screening contracts and any text more than 100 characters will be truncated in the tag.";
export const SalesTrainingPrompts = "Sales Training Prompts";
export const RolesDeleteSuccessfully = "Role(s) Deleted Successfully";
export const RolesDeleteError= "Selected role(s) are assigned to users. You must first unassign the user(s) to delete the Role(s)"
export const AddSalesTrainingPromptHeading = "Add Sales Training Prompt";
export const SaveSalesTrainingPrompt = "Prompt Saved Successfully.";
export const FailToSaveSalesTrainingPrompt = "Failed to save sales training prompt";
export const CancelDialogSalesTrainingPrompt = "Are you sure you want to discard the changes?";
export const EditSalesTrainingPromptHeader = "Edit Sales Training Prompt";
export const ViewSalesTrainingPromptHeader = "View Sales Training Prompt";
export const StandardSalesTrainingPromptHeader = "Standard Sales Training Prompt";
export const DeleteStandardSalesTrainingPromptMessage =  "You cannot delete Standard Sales Training Insight Prompt(s).";
export const StandardTemplateType ="Standard"
export const SingleRowDelete = "single-row-delete"
export const MultiRowDelete = "multi-row-delete"
export const DeleteSalesTrainingPromptErrorMessage =  "Error Deleting Sales Training Prompt.";
export const DeleteSalesTrainingPromptMessage ="Are you sure you want to delete the custom Sales Training insight Prompt(s)?"
export const DeleteSalesTrainingPromptSuccessMessage =  "Prompt(s) deleted successfully.";
export const SalesTrainingPromptClonedSuccessfully = "Prompt cloned successfully";
export const UpdateSalesTrainingPrompt = "Prompt updated successfully.";
export const AuthWithMicrosoft = "MICROSOFT";
export const CalendarNotConfigured = "Calendar is not configured";
export const EmployeeList = "Employee List";
export const DesignationList = "Designation List";
export const BillingDesignationList = "Billing Designation List";
export const PortFolioCompanyList = "PortFolioCompany List";
export const PortEmployeeProjectList = "EmployeeProject List";
export const AddDesignation = "Designation";
export const AddPortFolioCompany = "Portfolio Company";
export const AddBillingDesignation = "Billing Designation";
export const AddEmployeeSkill = "Employee Skill";
//PATHS
export const CSTPaths = {
  pathPreProcessing: "/pre-processing-contracts",
  pathContractDetails: "/contractdetails",
  pathPreProcessingContractDetails: "/preprocessingcontractdetails",
  pathUploadContract: "/uploadnewcontract",
  pathContractTerms: "/contract-terms",
  pathEditUploadedContract: "/edituploadedcontract",
  pathGetChildContractList: "/getchildcontractlist",
  pathUsersFeedback: "/users-feedback",
  pathContractReports: "/contract-reports",
  pathAddContractTerm: "/addcontractterm",
  pathContractTypes: "/contract-types",
  pathAddContractType: "/addcontracttype",
  pathEditContractType: "/editcontracttype",
  pathEditContractTerm: "/editcontractterm",
  pathScreeningTerms: "/screening-terms",
  pathAddScreeningTerm: "/add-screening-term",
  pathEditScreeningTerm: "/edit-screening-term",
};
export const REGPaths = {
  pathFollowUpEmailCreator: "/rras-follow-up-email-creator",
  pathSummaryTemplates: "/rras-summary-templates",
  pathAddSummaryTemplate: "/rras-add-summary-template",
  pathCreateEmail: "/rras-create-new-email",
  pathConnectCalender: "/rras-connect-calender",
  pathEditSummaryTemplate: "/rras-edit-summary-template",
  pathViewEmail: "/rras-view-email",
  pathEmailTemplates: "/rras-email-templates",
  pathAddEmailTemplate: "/rras-add-email-template",
  pathEditEmailTemplate: "/rras-edit-email-template",
  pathSync: "/sales-marketing-data-sync",
  pathMessages: "/messages",
  pathAddMessage: "/message-add",
  pathViewMessage: "/message-view",
  pathSalesTrainingPrompts: "/rras-sales-training-prompts",
  pathAddSalesTrainingPrompts: "/rras-add-sales-training-prompts",
  pathEditSalesTrainingPrompts: "/rras-edit-sales-training-prompts",
  pathSalesTrainingInsightsReport: "/rras-sales-training-insights-report"
};
export const SharedPaths = {
  pathRolesAndPermissions: "/roles-and-permissions",
  pathRoleAdd: "/role-add",
  pathRoleEdit: "/role-edit",
  pathUserList: "/user-list",
  pathAssignUser: "/assign-users",
  pathAddUser: "/user-add",
  pathEditUser: "/user-edit",
  pathProfile: "/profile",
  pathEditProfile: "/edit-profile",
  pathChangePassword: "/change-password",
};

export const PathEmployeeList = "/employee-list";
export const PathEmployeeSkills = "/employee-skills";
export const PathDesignationList = "/designation-list";
export const PathBillingDesignationList = "/billing-designation-list";
export const PathPortFolioCompanyList = "/portfoliocompany-list";
export const PathEmployeeProjectList = "/employeeproject-list";
export const PathEditDesignation = "/editdesignation";
export const PathAddDesignation = "/adddesignation";
export const PathEditPortFolioCompany = "/editportfoliocompany";
export const PathAddPortFolioCompany = "/addPortfoliocompany";
export const PathEditBillingDesignation = "/editbillingdesignation";
export const PathAddBillingDesignation = "/addbillingdesignation";
export const PathEditEmployeeSkill = "/editemployeeskill";
export const PathAddEmployeeSkill = "/addemployeeskill";

export const UserManagement = [{ id: "1", name: "Profile Information" }, { id: "2", name: "Users" }, { id: "3", name: "Roles & Permissions" }];
export const CSTCategory = [{ id: "1", name: "Contracts" }, { id: "2", name: "Contract Types" }, { id: "3", name: "Contract Terms" }, { id: "4", name: "Pre-Processing Contracts"}, { id: "5", name: "Screening Terms" }, { id: "6", name: "CST Reports" }];
export const REGCategory = [{ id: "1", name: "Summary Templates" }, { id: "2", name: "Meetings Summary & Email" }, { id: "3", name: "Email Templates" }, { id: "4", name: "AI Messages"}, { id: "5", name: "Sales & Marketing Data Sync" }, { id: "6", name: "Access Self Data" }, { id: "7", name: "Sales Training Prompts" },{ id: "8", name: "REG Reports" }];
export const StaffingCategory = [{ id: "1", name: "Employee List" }, { id: "2", name: "Designation List" }, { id: "3", name: "Billing Designation List" }, { id: "4", name: "Portfolio Company List"}, { id: "5", name: "Employee Project List" }];

export const DuplicateScreningTermName ="Screening term name already exists";
export const DuplicateScreningOrderNo ="Order # already exists";
export const InvalidTag="Invalid Tags(,;@)";
export const FailToSaveScreeningTerm = "Failed to save screening term";
export const ScreeningTermSavedSuccessfully ="Screening Term saved successfully";
export const TagRegex = /[,;@]/;

export const FailToSyncSalesMarketingData = "Failed to sync sales & marketing Data";
export const ValidGUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
export const TagCharLimit = 10;
export const userNameValidationText = "The username must be alphanumeric, may include _ , . , -,+,% and @ and should be max 255 characters long.";
export const userNameExist = "Username already exists"
export const failedToValidateUsername = "Failed to validate username"
export const profileUpdatedSuccessfully ="Profile updated successfully"
export const DeleteScreeningContractsMessage ="Are you sure you want to delete the contract(s)?"
export const DeleteScreeningContractsSuccessMessage =  "Contract(s) Deleted Successfully."
export const DuplicatePromptName ="Prompt name already exists";