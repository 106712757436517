import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { duplicateDesignation } from '../../networking/apiEndpoints';
import { DesignationCheckDuplicateModel } from '../../types/CheckDuplicateStaffingModel';
interface DesignationState {
    isDuplicate: string,
    responseStatus?: number
}

// Define an async thunk to make the API call
export const checkDuplicateDesignation = createAsyncThunk<
AxiosResponse|any,
{ id?: string, name?: string },
{
rejectValue:AxiosResponse
}
>(
    'contract/checkDuplicateDesignation',
    async (_DesignationCheckDuplicateModel:DesignationCheckDuplicateModel,thunkApi) => {
        try {
            var token = localStorage.getItem('token');
            var tenantId = localStorage.getItem('tenantId');
            const response = await axios.post(duplicateDesignation, _DesignationCheckDuplicateModel, {
                headers:{"Content-Type": "application/json",Authorization:`Bearer ${localStorage.getItem('token')}`},validateStatus: function(status){return status < 500;}
            });
            if(response.status === 401)
                {
                  return thunkApi.rejectWithValue(response);
                } 
            return response.data; // Return the data from the API response (should be a boolean)
        } catch (error) {
            throw error;
        }
    }
);

// Define the slice with reducers for pending, fulfilled, and rejected states
const checkDuplicateDesignationSlice = createSlice({
    name: 'checkDuplicateDesignation',
    initialState: {       
        isDuplicate: 'false'
    } as DesignationState,
    reducers: {},
    extraReducers: (builder) => {      
        builder.addCase(checkDuplicateDesignation.fulfilled, (state, action) => {
            state.isDuplicate = action.payload; // Set the isDuplicate state to the payload returned by the API
            if(action.payload !== undefined)
                {
                  state.responseStatus = action.payload.status;
                }  
        });       
    },
});

export default checkDuplicateDesignationSlice.reducer;
