import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { CircularProgress, Container, Grid, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import CustomSnackbar from "../../cst/Snackbar";
import DialogWithTwoButton from "../../cst/DialogWithTwoButton";
import TextBox from "../../cst/TextField";

import {
  DiscardChangesDialogMessage,
  FailedDuplicateCheck,
  RequiredFields,
  PathEmployeeSkills,
  EmployeeSkillExists,
  FailToSaveEmployeeSkill,
  CSTPaths,
} from "../../../utilities/Constants";

import Handle401Status from "../../../types/components/handleApiReasponseStatus";
import { ValidationRule } from "../../../types/components/validationType";
import { EmployeeSkillModel, EmployeeSkillCheckDuplicateModel } from "../../../types/components/staffingModel.";

import { getEmployeeSkillById } from "../../../redux/features/getEmployeeSkillByIdSlice";
import { checkDuplicateEmployeeSkill } from "../../../redux/features/checkDuplicateEmployeeSkill";
import { editEmployeeeSkill } from "../../../redux/features/editEmployeeSkillSlice";
import { addEmployeeSkillName } from "../../../redux/features/addEmployeeSkillSlice";

import { userInfoInterface } from "../../../types/pages/userInfoType";

export type FormErrors = {
  [key: string]: string;
};

const AddEmployeeSkill: React.FC<{ mode?: string; itemId?: string }> = ({
  mode,
  itemId,
}) => {
  const theme = useTheme();
  const [values, setValues] = useState<EmployeeSkillModel>({
    id: "",
    tenantid: "",
    employee: "",
    skill: "",
    experiencelevel: "",
    createdon: new Date().toISOString() ?? "2024-02-23T07:23:18.287Z",
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [initialEmployeeSkillName, setInitialEmployeeSkillName] =
    useState<string>("");
  const [initialExperienceLevel, setInitialExperienceLevel] =
    useState<string>("");
  const [isChanged, setIsChanged] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [showStatusValue, setShowStatusValue] = useState<boolean>(false);
  const [tittle, setTittle] = useState<string>("Add Employee Skill");
  const navigate = useNavigate();
  const addEmployeeSkillDispatch = useAppDispatch();
  let selectedTenantId = localStorage.getItem("tenantId");
  const isLoading = useAppSelector(
    (state) => state.addEditContractTypes.loading
  );

  const skillId: string = itemId ?? "";

  const useInfo = JSON.parse(
    localStorage.getItem("userInfo") ?? ""
  ) as userInfoInterface;

  const userId = useInfo.Id ?? "";

  useEffect(() => {
    if (mode === "edit") {
      setTittle("Edit Portfolio Company");
      fetchEmployeeSkill(skillId);
      setInitialEmployeeSkillName(values.skill ? values.skill : "");
      setInitialExperienceLevel(values.experiencelevel ? values.experiencelevel : "");
    }
  }, [mode]);

  function generate_uuid() {
    var dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
      function (c) {
        var rnd = Math.random() * 16;//random number in range 0 to 16
        rnd = (dt + rnd) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? rnd : (rnd & 0x3 | 0x8)).toString(16);
      });
  }

  const fetchEmployeeSkill = async (skillId: string) => {
    addEmployeeSkillDispatch(getEmployeeSkillById(skillId)).then((response) => {
      const employeeskill = response.payload?.data;
      const tenantId = localStorage.getItem("tenantId");
      let selectedEmployeeId = localStorage.getItem("employeeId");
      setValues({
        id: employeeskill.id,
        employee: selectedEmployeeId ?? "",
        skill: employeeskill.skill,
        experiencelevel: employeeskill.experiencelevel,
        tenantid: tenantId ?? "",
        createdby: employeeskill.createdby,
        createdon: employeeskill.createdon,
        modifiedby: employeeskill.modifiedby,
        modifiedon: employeeskill.modifiedon,
      });
      setInitialEmployeeSkillName(employeeskill.skill);
      setInitialExperienceLevel(employeeskill.experiencelevel);
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    switch (name) {
      case "name":
        setInitialEmployeeSkillName(value);
        break;
      case "experienceLevel":
        setInitialExperienceLevel(value);
        break;
      default:
        break;
    }
    const isChangeDetected = (value !== initialEmployeeSkillName || value !== initialExperienceLevel);
    setIsChanged(isChangeDetected); // Check if value changed
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));


    if (name === "name" && isChangeDetected) {
      try {
        const tenantId = localStorage.getItem("tenantId");
        const parm: EmployeeSkillCheckDuplicateModel = {
          id: values.id,
          name: value,
          tenantId: tenantId ?? "",
        }
        addEmployeeSkillDispatch(checkDuplicateEmployeeSkill(parm)).then(
          (response) => {
            if (response.payload?.status === 401) {
              Handle401Status(navigate, window);
            }
            const isDuplicate = Boolean(response.payload == "false" ? false : true);
            setIsDuplicate(isDuplicate);
            setErrors((prevErrors) => ({
              ...prevErrors,
              [name]: isDuplicate ? EmployeeSkillExists : "",
            }));
          }
        );
      } catch (error) {
        handleSnackbarOpen(FailedDuplicateCheck);
      }
    }
  };

  const validateForm = () => {
    const newErrors: FormErrors = {};
    Object.entries(values).forEach(([key, value]) => {
      const rules: ValidationRule = {
        required: true,
        maxLength: key === "name" ? 40 : 250,
      };
      if (rules.required && key === "name" && ((value != null || value == "") && !value.trim())) {
        newErrors["name"] = RequiredFields;
      }

      if (
        rules.maxLength && (key === "name") && (value != null &&
          value.length > rules.maxLength)
      ) {
        newErrors[
          key
        ] = `Maximum length exceeded (${rules.maxLength} characters max)`;
      }
      if (isDuplicate) {
        newErrors["name"] = EmployeeSkillExists;
      }
      if (key === "experienceLevel") {
        if (rules.required && !value.trim()) {
          newErrors["experienceLevel"] = RequiredFields;
        }
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0 && !isDuplicate;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    const errormsg = true;
    try {
      if (mode === "edit") {
        values.modifiedby = userId;
        let selectedEmployeeId = localStorage.getItem("employeeId") ?? "";
        values.employee = selectedEmployeeId;
        values.id = skillId;
        values.modifiedon = new Date().toISOString() ?? "2024-02-23T07:23:18.287Z";
        addEmployeeSkillDispatch(editEmployeeeSkill(values)).then((response) => {
          if (!response.payload) {
            handleSnackbarOpen(FailToSaveEmployeeSkill);
          } else {
            navigate(PathEmployeeSkills, {
              state: { id: selectedEmployeeId },
            });
          }
        });
      }
      else {
        const tenantId = localStorage.getItem("tenantId");
        let selectedEmployeeId = localStorage.getItem("employeeId") ?? "";
        values.tenantid = tenantId!;
        values.id = generate_uuid();
        values.createdby = userId;
        values.employee = selectedEmployeeId;
        values.modifiedby = userId;
        values.modifiedon = new Date().toISOString() ?? "2024-02-23T07:23:18.287Z";

        addEmployeeSkillDispatch(addEmployeeSkillName(values)).then((response) => {
          if (!response.payload) {
            handleSnackbarOpen(FailToSaveEmployeeSkill);
          } else {
            navigate(PathEmployeeSkills, {
              state: { id: selectedEmployeeId },
            });

          }
        });
      }

    } catch (error) {
      handleSnackbarOpen(FailToSaveEmployeeSkill, "error");
    }
  };

  const handleSnackbarOpen = (
    message: string,
    severity: "success" | "error" = "error"
  ) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  const handleNoButtonClick = () => {
    setisOpen(false);
  };
  const handleYesButtonClick = () => {
    navigate(CSTPaths.pathContractTypes);
  };
  const handleDialogClose = () => {
    setisOpen(false);
  };
  // Function to handle cancel button click
  const handleCancel = () => {
    // If text has been changed, show confirmation dialog
    if (isChanged) {
      setisOpen(true);
    } else {
      navigate(PathEmployeeSkills);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="container  contractTypeContainer">
      <div className="page-title">
        <h1>{tittle}</h1>
      </div>
      <Paper className="add-type-container main-paper" sx={{ pt: 2, pb: 2 }}>
        {isLoading && (
          <div className="loader-center">
            <CircularProgress />
          </div>
        )}
        { (
          <div className="loader-center">
            <CircularProgress />
          </div>
        )}

        <div className="">
          <Container maxWidth="xl">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextBox
                  name="skill"
                  label="Employee Skill *"
                  variant="outlined"
                  size="small"
                  value={values.skill}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                  className={
                    errors.name ? "errorField rounded-input" : "rounded-input"
                  }
                  maxLength={50}
                />
              </Grid>
              <Grid item xs={6}>
                <TextBox
                  name="experiencelevel"
                  label="Experience Level *"
                  variant="outlined"
                  size="small"
                  value={values.experiencelevel}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                  className={
                    errors.name ? "errorField rounded-input" : "rounded-input"
                  }
                  maxLength={1000}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={12} className="form-buttons">
                <Button
                  variant="outlined"
                  className="commoncancelbutton mr-20px"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="common-disablebutton"
                  onClick={handleSubmit}
                  disabled={!values.skill || !values.experiencelevel || isLoading || !isChanged || isDuplicate}
                >
                  {isLoading ? "Saving..." : "Save"}

                </Button>
              </Grid>

              <CustomSnackbar
                open={openSnackbar}
                message={snackbarMessage}
                handleClose={handleSnackbarClose}
                severity={snackbarSeverity}
              />
              <DialogWithTwoButton
                dialogOpen={isOpen}
                message={DiscardChangesDialogMessage}
                noBtnClick={handleNoButtonClick}
                yesBtnClick={handleYesButtonClick}
                dialogClose={handleDialogClose}
              />
            </Grid>
          </Container>
        </div>
      </Paper>
    </div>
  );
};
export default AddEmployeeSkill;
