import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getEmailTemplateList } from "../../../networking/apiEndpoints";

const initialState: any = {
  data: [],
  loading: false,
  error: null,
  responseStatus: 200,
};

export const getEmailTemplateListApi = createAsyncThunk<
  AxiosResponse | any,
  any,
  {
    rejectValue: AxiosResponse;
  }
>("addcontractdetails/post", async (emailTemplate, thunkApi) => {
  var token = localStorage.getItem("token");
  const response = await axios.post(getEmailTemplateList, emailTemplate, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    validateStatus: function (status) {
      return status < 500;
    },
  });
  if (response.status === 401) {
    return thunkApi.rejectWithValue(response);
  }
  const emailTemplateData = response.data.result.map((data) => ({
    id: data.id,
    name: data.name,
    type:
      data?.type === "S"
        ? "Standard"
        : data?.type === "P"
        ? "Custom"
        : "Unknown",
    DepartmentName: data.idDepartmentNavigation
      ? data.idDepartmentNavigation.name
      : "",
    LinkedSummaryTemplate: data.idSummaryTemplateNavigation
      ? data.idSummaryTemplateNavigation.name
      : "",
    CreatedBy: data.createdBy,
    CreatedOn: data.createdOn,
    modifiedBy: data.modifiedBy,
    modifiedOn: data.modifiedOn,
    idSummaryTemplateNavigation : data.idSummaryTemplateNavigation
    ? data.idSummaryTemplateNavigation
    : "",
    text : data.text,
    idDepartment : data.idDepartment,
    salesTrainingPromptEmailTemplates : data.salesTrainingPromptEmailTemplates
  }));  
  return emailTemplateData;
});

export const getEmailTemplateListSlice = createSlice({
  name: "addcontractdetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmailTemplateListApi.pending, (state) => {
      state.loading = true;
      state.error = '';
    })
    .addCase(getEmailTemplateListApi.fulfilled, (state, action) => {    
      state.data = action.payload;
      state.loading = false;
    }) 
    .addCase(getEmailTemplateListApi.rejected, (state, action) => {
      state.loading = false;
      if (action.payload !== undefined) {
        state.responseStatus = action.payload.status;
      }
    })
  },
});

export default getEmailTemplateListSlice.reducer;
