import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, circularProgressClasses, Menu, MenuItem, Paper } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import GoogleIcon from '@mui/icons-material/Google';
import VideocamIcon from '@mui/icons-material/Videocam';
import { HeadCell } from '../../../../types/components/contractTypeModel';
import EnhancedTable from '../../../cst/ViewTable/ListViewTable';
import { AuthWithMicrosoft, CalendarNotConfigured, defaultPageNumber } from '../../../../utilities/Constants';
import { ResponseAuthProviderConfigModel } from '../../../../types/components/rass/authProviderConfigModel';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { getMSALInstance, loginWithPopup } from '../../../../redux/features/rras/loginWithMicrosoftSlice';
import { fetchCalendarMeetingList } from '../../../../redux/features/rras/getCalendarMeetingListSlice';
import { RequestCalendarMeetingModel, ResponseCalendarMeetingModel } from '../../../../types/components/rass/calendarMeetingModel';
import CustomSnackbar from '../../../cst/CustomSnackbar';
import TranscriptData from './Transcript';
import { useSelector } from 'react-redux';

interface ImportFromCalendarProps {
  authConfigs: ResponseAuthProviderConfigModel[];
  isImportTranscript: (value: boolean) => void;
  isImportButtonClicked: boolean;
  onResetTranscript?: () => void;
  externalSelectedRadioId?: string | null;
  onRadioSelect?: (selectedId: string) => void;
  onTranscriptManualChange?: (text: string) => void;
  onTranscriptModeChange?: (mode: "imported" | "manual" | null) => void;
  onTranscriptChange?: (text: string) => void; 
  initialTranscript?: string; 
  currentTranscriptMode?: "imported" | "manual" | null; 
}

const MeetingTranscript: React.FC<ImportFromCalendarProps> = ({ 
  authConfigs, 
  isImportTranscript, 
  isImportButtonClicked,
  onResetTranscript,
  externalSelectedRadioId,
  onRadioSelect,
  onTranscriptManualChange,
  onTranscriptModeChange,
  onTranscriptChange,
  initialTranscript = "",
  currentTranscriptMode = null
}) => {  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [rowsPerPage, setRowsPerPage] = useState<string>(
    localStorage.getItem(window.location + "RowsPerPage") ?? defaultPageNumber
  );
  const open = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAuthConfigError, setIsAuthConfigError] = useState<boolean>(false);
  const dispatch = useAppDispatch(); 
  const result = useSelector((state: RootState) => state.calendarMeetingList.result);
  
  const effectiveTranscriptMode = currentTranscriptMode;

  const headCells: HeadCell<string>[] = [
    {
      id: "meetingID",
      numeric: false,
      disablePadding: true,
      label: "id",
      visible: false,
      className: "id-cell",
    },
    {
      id: "recordingDateTime",
      numeric: false,
      disablePadding: false,
      label: "Meeting Time",
      sort: true,
      viewAction: true,
      className: "contract-cell",
    },
    {
      id: "meetingTitle",
      numeric: false,
      disablePadding: false,
      label: "Meeting Title",
      viewAction: true,
      sort: false,
      className: "condescription-cell",
    },
    {
      id: "joinedAttendees",
      numeric: false,
      disablePadding: false,
      label: "Meeting Attendees",
      viewAction: true,
      sort: false,
      className: "condescription-cell",
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAuthLogin = (providerName: string) => {
    setIsAuthConfigError(false);
    handleClose();
    setIsLoading(true);
    const selectedAuth = authConfigs?.find(c => c.providerName.toLocaleUpperCase() === providerName.toLocaleUpperCase());
    if(!selectedAuth){
      setIsAuthConfigError(true);
      setIsLoading(false);       
      return;
    }         
    if(providerName === AuthWithMicrosoft){
      msAuthLogin(selectedAuth.providerTenantId, selectedAuth.providerClientId, selectedAuth.providerClientSecretId);     
    }       
  };

  const msAuthLogin = async (msTenantId: string, msClientId: string, msClientSecretId: string) => {
    let accessToken: string | null;
    const msalInstance = getMSALInstance(msClientId, msTenantId);
    await msalInstance.initialize();

    if(sessionStorage.getItem("msAuthAccessToken") && sessionStorage.getItem("msAuthTokenExpiration")){
      accessToken = sessionStorage.getItem("msAuthAccessToken");
      const expirationTime = sessionStorage.getItem("msAuthTokenExpiration");
      if (Date.now() > Number(expirationTime)) {
        sessionStorage.removeItem("msAuthAccessToken");
        sessionStorage.removeItem("msAuthTokenExpiration");
        accessToken = await loginWithPopup(msalInstance);
        if(accessToken){
          const expirationTime = Date.now() + 1800 * 1000;
          sessionStorage.setItem("msAuthAccessToken", accessToken);
          sessionStorage.setItem("msAuthTokenExpiration", expirationTime.toString());
        }
      } 
      const requestMeetingModel: RequestCalendarMeetingModel = {tenantId: msTenantId, clientId: msClientId, clientSecretId: msClientSecretId, accessToken: accessToken ?? "" };
      fetchCalendarMeetings(requestMeetingModel);
      return;
    } 

    accessToken = await loginWithPopup(msalInstance);
    if(accessToken){
      const expirationTime = Date.now() + 1800 * 1000;
      sessionStorage.setItem("msAuthAccessToken", accessToken);
      sessionStorage.setItem("msAuthTokenExpiration", expirationTime.toString());
      const requestMeetingModel: RequestCalendarMeetingModel = {tenantId: msTenantId, clientId: msClientId, clientSecretId: msClientSecretId, accessToken: accessToken ?? "" };
      fetchCalendarMeetings(requestMeetingModel);
    }     
  };

  const fetchCalendarMeetings = (requestMeetingModel: RequestCalendarMeetingModel) => {
    dispatch(fetchCalendarMeetingList(requestMeetingModel)).then(() => {
      setIsLoading(false);
    });
  };

  const handleRadioSelect = (selectedId: string, isSelected: boolean) => {
    if (!result || !Array.isArray(result)) {
      return;
    }
    const selectedMeeting = result.find(
      (m: ResponseCalendarMeetingModel) => m.meetingID === selectedId
    );
    if (selectedMeeting) {
      if (onTranscriptManualChange) {
        onTranscriptManualChange("");
      }
      if (onTranscriptChange) {
        onTranscriptChange(selectedMeeting.transScript);
      }
      isImportTranscript(true);
      if (onRadioSelect) {
        onRadioSelect(selectedId);
      }
    }
  };

  const prevImportClickedRef = useRef<boolean>(isImportButtonClicked);

  useEffect(() => {
    if (isImportButtonClicked && !prevImportClickedRef.current) {
      if (onTranscriptModeChange) {
        onTranscriptModeChange("imported");
      }
    }
    prevImportClickedRef.current = isImportButtonClicked;
  }, [isImportButtonClicked, onTranscriptModeChange]);

  const handleManualTranscript = () => {
    if (onResetTranscript) {
      onResetTranscript();
    }
    if (onTranscriptModeChange) {
      onTranscriptModeChange("manual");
    }
    handleClose();
  };

  return (
    <div className="calendar-wrapper">
      <div className='button-wrapper'>
        {effectiveTranscriptMode === null && (
          <Button
            id="import-calendar-button"
            aria-controls={open ? 'import-calendar-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            startIcon={<KeyboardArrowDownIcon />}
          >
            Meeting Transcript From
          </Button>
        )}
        <Menu
          id="import-calendar-menu"
          className="calendar-menu" 
          MenuListProps={{
            'aria-labelledby': 'import-calendar-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={ () => handleAuthLogin(AuthWithMicrosoft)} disableRipple>
            <MicrosoftIcon color="primary" sx={{ fontSize: 16, marginRight: 1}} />
            Microsoft Teams
          </MenuItem>
          <MenuItem disabled onClick={handleClose} disableRipple>
            <GoogleIcon color="primary" sx={{ fontSize: 16, marginRight: 1 }}/>
            Google Meet
          </MenuItem>
          <MenuItem disabled onClick={handleClose} disableRipple>
            <VideocamIcon color="primary" sx={{ fontSize: 18, marginRight: 1 }}/>
            Zoom
          </MenuItem>
          <MenuItem onClick={handleManualTranscript} disableRipple>
            <FormatAlignLeftIcon color="primary" sx={{ fontSize: 15, marginRight: 1 }}/>
            Manual Copy and Paste Transcript
          </MenuItem>
        </Menu>
      </div>
      <div className='grid-space'>
        {isAuthConfigError && (
          <div>
            <CustomSnackbar
              message={CalendarNotConfigured}
              severity="error"
            />
          </div>
        )}
        {isLoading && (
          <div className="loader-center">
            <CircularProgress />
          </div>
        )}

        {effectiveTranscriptMode !== null ? (
          <TranscriptData 
            transcript={initialTranscript} 
            isManual={effectiveTranscriptMode === "manual"} 
            onTranscriptChange={ effectiveTranscriptMode === "manual" ? onTranscriptManualChange : undefined }
          />
        ) : (
          <div>
            {!isLoading && (
              <EnhancedTable
                Data={result}
                column={headCells}
                handleEdit={() => {}}
                enableCheckBox={false}
                enableRadioButton={true}
                handleDelete={() => {}}
                handleView={() => {}}
                rowsPerTable={parseInt(rowsPerPage)}
                handleRadioSelect={handleRadioSelect}
                externalSelectedRadioId={externalSelectedRadioId}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MeetingTranscript;