import React from 'react';
import AddEmployeeSkill from '../../../components/Staffing/EmployeeSkillList/AddEmployeeSkill';
import { useParams } from 'react-router-dom';

const EditEmployeeSkill :React.FC<{}> = ({ }) => {
  const {id} = useParams();  
  return (
    <div>
    <AddEmployeeSkill mode={'edit'} itemId={id}/>
    </div>
  );
};
export default EditEmployeeSkill; 