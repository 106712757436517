import React, { useState } from "react";
import { TextField, Button } from "@mui/material";

interface TranscriptDataProps {
  transcript: string | null;
  isManual?: boolean;
  onTranscriptChange?: (value: string) => void;
}

const TranscriptData: React.FC<TranscriptDataProps> = ({
  transcript,
  isManual = false,
  onTranscriptChange,
}) => {
  const [editableTranscript, setEditableTranscript] = useState(transcript);

  const handleTranscriptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditableTranscript(event.target.value);
    if (onTranscriptChange) {
      onTranscriptChange(event.target.value);
    }
  };

  return (
    <div>
      {isManual ? (
        <TextField
        multiline
        fullWidth
        variant="outlined"
        value={editableTranscript}
        onChange={handleTranscriptChange}
        placeholder="Enter Transcript Here"
        sx={{
          "& .MuiInputBase-root": {
            minHeight: "45vh",
            display: "flex",
            alignItems: "flex-start",
          },
          "& textarea": {
            minHeight: "45vh", 
          },
        }}
      />
      
      ) : (
        <div
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
            backgroundColor: "#f9f9f9",
          }}
          dangerouslySetInnerHTML={{ __html: transcript || "" }}
        />
      )}
    </div>
  );
};

export default TranscriptData;