import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { Link } from "react-router-dom";
import * as icons from "@mui/icons-material";
import { Divider, Typography } from "@mui/material";
import { ILeftNavListWrap } from "../../types/components/leftNavTypes";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { setActiveMenuItem } from "../../redux/features/getMenuSlice";
import { useAppSelector } from "../../redux/store";
import { useDispatch } from "react-redux";
import React from "react";

const LeftNavListWrap = (props: ILeftNavListWrap) => {
  const { leftNavList, selectedIndex, setSelectedIndex } = props;
  const leftNavkeys = Object.keys(leftNavList);
  const activeMenu = useAppSelector(state=>state.getMenu.activeMenu);
  const dispatch =useDispatch();
  const handleMenuItemClick = (menuItem : string) =>{    
    dispatch(setActiveMenuItem(menuItem))    
  }
  const LeftListItem = leftNavkeys.map((key, num) => (
    <React.Fragment  key={num}>
      <Typography variant="body2" noWrap className="contractManagementWrap">
        {key
          .replace(/([A-Z])/g, " $1")
          .trim()
          .toUpperCase()}
      </Typography>
      <List className="menuList">
        {leftNavList[key].map((link, index) => {          
          const Icon = icons[link.icon];
          const visibilitySubMenu = link.visibilitySubMenu ?? true;
          return link.subMenu.length === 0 ? (
            <Link className={`linkWrapper ${!visibilitySubMenu ? "hide_class" : ""}`} to={link.url} key={`${link.name}-${index}`} >
              <ListItem className="leftNavLinkItem">
                <ListItemButton
                  className="leftNavList"
                  onClick={()=> handleMenuItemClick(link.name)}
                          selected = {activeMenu  === link.name}
                >
                  <Icon                    
                    className="leftNavIcon"
                  />
                  <span>{link.name}</span>             
                </ListItemButton>
              </ListItem>
            </Link>
          ) : (
            <Accordion className={`subMenuAccordionWrap ${!visibilitySubMenu ? "hide_class" : ""}`} key={`${link.name}-${index}`}>
              <AccordionSummary
                expandIcon={<icons.ArrowForwardIos className="expandIconWrapper"/>}
                className="subMenuAccordionSummary"
              >
                <Icon className="leftNavIcon" />
                <span className="subMenuAccordionName">{link.name}</span>
              </AccordionSummary>
              <AccordionDetails>
                {link.subMenu.map((subMenuData, subMenuIndex) => {
                  const Icon = icons[subMenuData.icon];
                  const visibleSubMenuItem = subMenuData.visibilitySubMenuItem ?? true;
                  return (
                    <Link className="subMenuLinkWrapper" to={subMenuData.url}   key={`${subMenuData.name}-${subMenuIndex}`} >
                      <ListItem
                         className={`leftNavLinkItem ${!visibleSubMenuItem ? "hide_class" : ""}`}
                      >
                        <ListItemButton
                          className="leftNavList"                          
                          onClick={()=> handleMenuItemClick(subMenuData.name)}
                          selected = {activeMenu  === subMenuData.name}
                        >
                          <Icon
                            className={
                              selectedIndex === `${key}`
                                ? `subMenuSelectedLeftNavIcon`
                                : `subMenuLeftNavIcon`
                            }
                          />
                          {subMenuData.name}
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </List>
      {leftNavkeys.length !== num + 1 && <Divider className="leftNavDivider" />}
    </React.Fragment>
  ));

  return <>{LeftListItem}</>;
};

export default LeftNavListWrap;
