import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getCalendarMeetingsEndPoint } from "../../../networking/apiEndpoints";
import { ResponseModel } from "../../../types/components/responseModel";
import { ResponseCalendarMeetingModel, RequestCalendarMeetingModel } from "../../../types/components/rass/calendarMeetingModel";

const initialState: ResponseModel = {
  status: false,
  message: "",
  result: [],
};

export const fetchCalendarMeetingList = createAsyncThunk<
  any,
  RequestCalendarMeetingModel,
  {
    rejectValue: AxiosResponse;
  }
>(
  "calendarMeetingList/fetch",
  async (data: RequestCalendarMeetingModel, thunkApi) => {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${getCalendarMeetingsEndPoint}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Cache-Control": "public, max-age=3600, must-revalidate",
        Pragma: "no-cache",
      },
      validateStatus: (status) => status < 500,
    });

    if (response.status === 401) {
      return thunkApi.rejectWithValue(response);
    }

    const convertUtcToLocal = (utcDateString: string) => {
      const date = new Date(utcDateString);
      const options: Intl.DateTimeFormatOptions = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
      return formattedDate.replace(/(\d{2})\/(\d{2})\/(\d{4}),/, "$1/$2/$3,");
    };

    const filteredResult = response.data?.result.map((c: any) => ({
      ...c,
      joinedAttendees: c.attendeeList,
      recordingDateTime: convertUtcToLocal(c.recordingDateTime),
    })) || [];

    return {
      ...response.data,
      result: filteredResult,
    };
  }
);

export const getCalendarMeetingListSlice = createSlice({
  name: "calendarMeetings",
  initialState,
  reducers: {
    resetCalendarMeetingList: (state) => {
      state.status = false;
      state.message = "";
      state.result = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCalendarMeetingList.fulfilled, (state, action) => {
      state.status = action.payload?.status;
      state.result = action.payload?.result as ResponseCalendarMeetingModel[];
    });
  },
});

export const { resetCalendarMeetingList } = getCalendarMeetingListSlice.actions;
export default getCalendarMeetingListSlice.reducer;
