import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { addDesignation } from "../../networking/apiEndpoints";
import { DesignationModelState } from "../../types/components/staffingModel.";

const initialState: DesignationModelState = {
    designationModel: [],
    loading: false,
    error: null,
    showAlert: false,
    responseStatus: 200
};

// Add/Edit Contract Type
export const addDesignationName = createAsyncThunk<
    AxiosResponse | any,
    any,
    {
        rejectValue: AxiosResponse;
    }>(
        "addEditDesignation/post",
        async (formData: any, thunkApi) => {
            try {
                var token = localStorage.getItem('token');
                const response = await axios.post(addDesignation, formData, {
                    headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
                });
                if (response.status === 401) {
                    return thunkApi.rejectWithValue(response);
                }
                return response.data;
            } catch (err) {
                console.log(err);
            }
        }
    );

export const addDesignationSlice = createSlice({
    name: "addDesignationSlice",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(addDesignationName.fulfilled, (state, action) => {
            state.responseStatus = action.payload;
        });
    },
});

export default addDesignationSlice.reducer;