import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getDesignationNameById } from '../../networking/apiEndpoints';
import { DesignationFilterModel } from '../../types/components/staffingFilterModel';
const initialState: any = {
    designationModel: [],
    isLoadingDesignation:false,
    responseStatus: 200
};

export const getDesignationById = createAsyncThunk(
  "designationModel/getDesignationById",
  async (_DesignationFilterModel:DesignationFilterModel, thunkApi) => { 

      try {
          var token = localStorage.getItem('token');
          const response = await axios.put(getDesignationNameById + '/' + _DesignationFilterModel.id , _DesignationFilterModel, {
              headers: { "Content-Type": "application/json", Authorization:`Bearer ${token}`}
          });
          if (response.status === 401) {
              return thunkApi.rejectWithValue(response);
          }
          return response.data;
      } catch (err) {
          console.log(err);
      }
  }
);

export const getDesignationByIdSlice = createSlice({
    name: "designationlist",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDesignationById.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(getDesignationById.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
            })
            .addCase(getDesignationById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? '';
                if(action.payload !== undefined)
                    {
                      state.responseStatus = 401;
                    }
            });
    },
});

export default getDesignationByIdSlice.reducer;
