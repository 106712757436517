import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { CircularProgress, Container, Grid, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CustomSnackbar from "../../cst/Snackbar";
import DialogWithTwoButton from "../../cst/DialogWithTwoButton";
import TextBox from "../../cst/TextField";

import {
  DiscardChangesDialogMessage,
  FailedDuplicateCheck,
  RequiredFields,
  PathPortFolioCompanyList,
  PortFolioCompanyExists,
  FailToSavePortFolioCompany,
  CSTPaths,
} from "../../../utilities/Constants";

import Handle401Status from "../../../types/components/handleApiReasponseStatus";
import { ValidationRule } from "../../../types/components/validationType";
import { PortfolioCompanyModel, PortFolioCompanyCheckDuplicateModel } from "../../../types/components/staffingModel.";
import { getPortFolioCompanyById } from "../../../redux/features/getPortFolioCompanyByIdSlice";
import { checkDuplicatePortFolioCompany } from "../../../redux/features/checkDuplicatePortFolioCompany";
import { editPortFolioCompany } from "../../../redux/features/editPortFolioCompanySlice";
import { addPortFolioCompanyName } from "../../../redux/features/addPortFolioCompanySlice";
import { userInfoInterface } from "../../../types/pages/userInfoType";

export type FormErrors = {
  [key: string]: string;
};

const AddPortFolioCompany: React.FC<{ mode?: string; itemId?: string }> = ({
  mode,
  itemId,
}) => {
  const theme = useTheme();
  const [values, setValues] = useState<PortfolioCompanyModel>({
    id: "",
    tenantid: "",
    name: "",
    description: "",
    isactive: 0,
    showStatus: false,
    createdon: new Date().toISOString() ?? "2024-02-23T07:23:18.287Z",
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [initialPortFolioComapnyName, setInitialPortFolioComapnyName] =
    useState<string>("");
  const [initialDescription, setInitialDescription] =
    useState<string>("");
  const [isChanged, setIsChanged] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [showStatusValue, setShowStatusValue] = useState<boolean>(false);
  const [tittle, setTittle] = useState<string>("Add Portfolio Company");
  const navigate = useNavigate();
  const addPortFolioPatchDispatch = useAppDispatch();
  let selectedTenantId = localStorage.getItem("tenantId");
  const isLoading = useAppSelector(
    (state) => state.addEditContractTypes.loading
  );
  const isLoadingContractType = useAppSelector(
    (state) => state.getContractTypeId.isLoadingContractType
  );
  const [editPortFolioCompanyPermissionNoAccess, setEditPortFolioCompanyPermissionNoAccess] = useState<boolean>(true);
  const [addPortFolioCompanyPermissionNoAccess, setAddPortFolioCompanyPermissionNoAccess] = useState<boolean>(true);

  const portfolioId:string = itemId ?? "";

  const useInfo = JSON.parse(
    localStorage.getItem("userInfo") ?? ""
  ) as userInfoInterface;

  const userId = useInfo.Id ?? "";

  useEffect(() => {
    if (mode === "edit") {

      setTittle("Edit Portfolio Company");
      fetchPortFolioComapany(portfolioId);
      setInitialPortFolioComapnyName(values.name ? values.name : "");
      setInitialDescription(values.description ? values.description : "");
      setShowStatusValue(values.isactive==1?true:false);
    }
  }, [mode]);

  function generate_uuid() {
    var dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
      function (c) {
        var rnd = Math.random() * 16;//random number in range 0 to 16
        rnd = (dt + rnd) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? rnd : (rnd & 0x3 | 0x8)).toString(16);
      });
  }

  const fetchPortFolioComapany = async (portfolioId: string) => {
    addPortFolioPatchDispatch(getPortFolioCompanyById(portfolioId)).then((response) => {

      const portfoliocompany = response.payload?.portfoliocompany;
      const tenantId = localStorage.getItem("tenantId");
      setValues({
        id: portfoliocompany.id,
        name: portfoliocompany.name,
        description: portfoliocompany.description,
        isactive: portfoliocompany.isactve,
        tenantid: tenantId ?? "",
        createdby: portfoliocompany.createdby,
        createdon: portfoliocompany.createdon,
        modifiedby: portfoliocompany.modifiedby,
        modifiedon: portfoliocompany.modifiedon,
      });
      setInitialPortFolioComapnyName(portfoliocompany.name);
      setInitialDescription(portfoliocompany.description);
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    switch (name) {
      case "name":
        setInitialPortFolioComapnyName(value);
        break;
      case "description":
        setInitialDescription(value);
        break;
      case "showStatus":
        if (e.target.checked) {
          setShowStatusValue(true);
        }
        else {
          setShowStatusValue(false);
        }
        break;
      default:
        break;
    }
    const isChangeDetected = value !== initialPortFolioComapnyName;
    setIsChanged(isChangeDetected); // Check if value changed
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));


    if (name === "name" && isChangeDetected) {
      try {
        const tenantId = localStorage.getItem("tenantId");
        const parm: PortFolioCompanyCheckDuplicateModel = {
          id: values.id,
          name: value,
          tenantId: tenantId ?? "",
        }
        addPortFolioPatchDispatch(checkDuplicatePortFolioCompany(parm)).then(
          (response) => {
            if (response.payload?.status === 401) {
              Handle401Status(navigate, window);
            }
            const isDuplicate = Boolean(response.payload == "false" ? false : true);
            setIsDuplicate(isDuplicate);
            setErrors((prevErrors) => ({
              ...prevErrors,
              [name]: isDuplicate ? PortFolioCompanyExists : "",
            }));
          }
        );
      } catch (error) {
        handleSnackbarOpen(FailedDuplicateCheck);
      }
    }
  };

  const validateForm = () => {
    const newErrors: FormErrors = {};
    Object.entries(values).forEach(([key, value]) => {
      const rules: ValidationRule = {
        required: true,
        maxLength: key === "name" ? 40 : 250,
      };
      if (rules.required && key === "name" && ((value != null || value == "") && !value.trim())) {
        newErrors["name"] = RequiredFields;
      }

      if (
        rules.maxLength && (key === "name") && (value != null &&
          value.length > rules.maxLength)
      ) {
        newErrors[
          key
        ] = `Maximum length exceeded (${rules.maxLength} characters max)`;
      }
      if (isDuplicate) {
        newErrors["name"] = PortFolioCompanyExists;
      }
      if (key === "description") {
        if (rules.required && !value.trim()) {
          newErrors["description"] = RequiredFields;
        }
      }
      if (key === "description") {
        if (rules.required && !value.trim()) {
          newErrors["description"] = RequiredFields;
        }
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0 && !isDuplicate;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    const errormsg = true;
    try {
      if (mode === "edit") {
        values.modifiedby = userId;
        values.id = portfolioId;
        values.isactive = (showStatusValue == true ? 0 : 1)
        values.modifiedon = new Date().toISOString() ?? "2024-02-23T07:23:18.287Z";
        addPortFolioPatchDispatch(editPortFolioCompany(values)).then((response) => {
          if (!response.payload) {
            handleSnackbarOpen(FailToSavePortFolioCompany);
          } else {
            navigate(PathPortFolioCompanyList);
          }
        });
      }
      else {
        const tenantId = localStorage.getItem("tenantId");
        values.tenantid = tenantId!;
        values.id = generate_uuid();
        values.createdby = userId;
        values.isactive = (showStatusValue == true ? 0 : 1)
        addPortFolioPatchDispatch(addPortFolioCompanyName(values)).then((response) => {
          if (!response.payload) {
            handleSnackbarOpen(FailToSavePortFolioCompany);
          } else {
            navigate(PathPortFolioCompanyList);
          }
        });
      }

    } catch (error) {
      handleSnackbarOpen(FailToSavePortFolioCompany, "error");
    }
  };

  const handleSnackbarOpen = (
    message: string,
    severity: "success" | "error" = "error"
  ) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  const handleNoButtonClick = () => {
    setisOpen(false);
  };
  const handleYesButtonClick = () => {
    navigate(CSTPaths.pathContractTypes);
  };
  const handleDialogClose = () => {
    setisOpen(false);
  };
  // Function to handle cancel button click
  const handleCancel = () => {
    // If text has been changed, show confirmation dialog
    if (isChanged) {
      setisOpen(true);
    } else {
      navigate(PathPortFolioCompanyList);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="container  contractTypeContainer">
      <div className="page-title">
        <h1>{tittle}</h1>
      </div>
      <Paper className="add-type-container main-paper" sx={{ pt: 2, pb: 2 }}>
        {isLoading && (
          <div className="loader-center">
            <CircularProgress />
          </div>
        )}
        {isLoadingContractType && (
          <div className="loader-center">
            <CircularProgress />
          </div>
        )}

        <div className="">
          <Container maxWidth="xl">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextBox
                  name="name"
                  label="Portfolio Comapany Name *"
                  variant="outlined"
                  size="small"
                  value={values.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                  className={
                    errors.name ? "errorField rounded-input" : "rounded-input"
                  }
                  maxLength={50}
                />
              </Grid>
              <Grid item xs={6}>
                <TextBox
                  name="description"
                  label="Portfolio Comapany description *"
                  variant="outlined"
                  size="small"
                  value={values.description}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                  className={
                    errors.name ? "errorField rounded-input" : "rounded-input"
                  }
                  maxLength={1000}
                />
              </Grid>
              <Grid item xs={6} >
                <FormGroup>
                  <span className="show-source"><FormControlLabel control={<Checkbox />} label="Show Status" 
                  name="showStatus" 
                  checked={showStatusValue} onChange={handleChange} /></span>
                </FormGroup>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={12} className="form-buttons">
                <Button
                  variant="outlined"
                  className="commoncancelbutton mr-20px"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="common-disablebutton"
                  onClick={handleSubmit}
                  disabled={!values.name || !values.description || !values.showStatus || isLoading || !isChanged || isDuplicate}
                >
                  {isLoading ? "Saving..." : "Save"}

                </Button>
              </Grid>

              <CustomSnackbar
                open={openSnackbar}
                message={snackbarMessage}
                handleClose={handleSnackbarClose}
                severity={snackbarSeverity}
              />
              <DialogWithTwoButton
                dialogOpen={isOpen}
                message={DiscardChangesDialogMessage}
                noBtnClick={handleNoButtonClick}
                yesBtnClick={handleYesButtonClick}
                dialogClose={handleDialogClose}
              />
            </Grid>
          </Container>
        </div>
      </Paper>
    </div>
  );
};
export default AddPortFolioCompany;
