import { ContractTerm, DataObject, HeadCell } from "../types/components/contractTypeModel";

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  export function getComparator<Key extends keyof any>(
    order: 'asc' | 'desc',
    orderBy: Key
  ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
      ? (a, b) => (b[orderBy] as any) - (a[orderBy] as any)
      : (a, b) => (a[orderBy] as any) - (b[orderBy] as any);
  }
  export function  GetTransData<T>(anydata: any[], columnNew: HeadCell<T>[]) {    
  const dataObjects: DataObject[] = (Array.isArray(anydata) ? anydata : []).map((contractTerm,index) => {    
    const resultObject: DataObject = {};
    columnNew.forEach((column) => {
        const { id } = column;
        if (contractTerm.hasOwnProperty(id)) {
            resultObject[id] = contractTerm[id];
        }
    });
    resultObject['Indexid']=index.toString();
    resultObject['imagePath'] = contractTerm.imagePath;
    return resultObject;
}) 
return dataObjects;}
export function GetFilteredData(data:ContractTerm[]|undefined,contractTypeId: string, methodValue: string, termName: string){
  return   data?.filter(x=>
    x.termName.toLowerCase().includes(termName.toLowerCase())
    );
}
export function ContainsSpecialCharacters(str: string): boolean {
  // Regular expression to match %, #, ?,+
  const specialCharRegex = /[%#?+]/;
  return specialCharRegex.test(str);
}