import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { updateEmployeeSkill } from "../../networking/apiEndpoints";
import { EmployeeSkillModelState } from "../../types/components/staffingModel.";

const initialState: EmployeeSkillModelState = {
    employeeSkillModel: [],
    loading: false,
    error: null,
    showAlert: false,
    responseStatus: 200
};

// Add/Edit Contract Type
export const editEmployeeeSkill = createAsyncThunk<
    AxiosResponse | any,
    any,
    {
        rejectValue: AxiosResponse;
    }>(
        "editEmployeeeSkill/put",
        async (formData: any, thunkApi) => {
            try {
                var token = localStorage.getItem('token');
                var tenantId = localStorage.getItem('tenantId');
                const response = await axios.put(updateEmployeeSkill + '/' + formData.id, formData, {
                    headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
                });
                if (response.status === 401) {
                    return thunkApi.rejectWithValue(response);
                }
                return response.data;
            } catch (err) {
                console.log(err);
            }
        }
    );

export const editEmployeeeSkillSlice = createSlice({
    name: "editEmployeeeSkillSlice",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(editEmployeeeSkill.fulfilled, (state, action) => {
            state.responseStatus = action.payload;
        });
    },
});

export default editEmployeeeSkillSlice.reducer;