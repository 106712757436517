import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getBillingDesignationList } from "../../networking/apiEndpoints";
import { BillingDesignationModel } from "../../types/components/staffingModel.";

export const fetchBillingDesignationList = createAsyncThunk(
    "billingDesignationModel/fetch",
    async (_, thunkApi) => {

        try {
            var token = localStorage.getItem('token');
            const response = await axios.get(getBillingDesignationList, {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (response.status === 401) {
                return thunkApi.rejectWithValue(response);
            }
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
);

interface BillingDesignationState {
    data: BillingDesignationModel[] | undefined;
    loading: boolean;
    error: string | null;
    responseStatus: number;
}

const initialState: BillingDesignationState = {
    data: [],
    loading: false,
    error: null,
    responseStatus: 200
};

export const billingDesignationListSlice = createSlice({
    name: "billingdesignationlist",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBillingDesignationList.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(fetchBillingDesignationList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
                if (action.payload !== undefined) {
                    state.responseStatus = action.payload.status;
                }
            })
            .addCase(fetchBillingDesignationList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? '';
                if (action.payload !== undefined) {
                    state.responseStatus = 401;
                }
            });
    },
});
export default billingDesignationListSlice.reducer;