import React from 'react';
import { useParams } from 'react-router-dom';
import AddBillingDesignation from './AddBillingDesignation';

const EditBillingDesignation :React.FC<{}> = ({ }) => {
  const {id} = useParams();  
  return (
    <div>
    <AddBillingDesignation mode={'edit'} itemId={id}/>
    </div>
  );
};
export default EditBillingDesignation; 