import React from 'react';
import AddPortFolioCompany from '../../../components/Staffing/PortFolioCompanyList/AddPortFolioCompany';
import { useParams } from 'react-router-dom';

const EditPortFolioCompany :React.FC<{}> = ({ }) => {
  const {id} = useParams();  
  return (
    <div>
    <AddPortFolioCompany mode={'edit'} itemId={id}/>
    </div>
  );
};
export default EditPortFolioCompany; 