import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { updatePortfolioCompany } from "../../networking/apiEndpoints";
import { PortFolioCompanyModelState } from "../../types/components/staffingModel.";

const initialState: PortFolioCompanyModelState = {
    portfolioCompanyModel: [],
    loading: false,
    error: null,
    showAlert: false,
    responseStatus: 200
};

// Add/Edit Contract Type
export const editPortFolioCompany = createAsyncThunk<
    AxiosResponse | any,
    any,
    {
        rejectValue: AxiosResponse;
    }>(
        "addEditPortFolioCompany/post",
        async (formData: any, thunkApi) => {
            try {
                var token = localStorage.getItem('token');
                var tenantId = localStorage.getItem('tenantId');
                const response = await axios.put(updatePortfolioCompany + '/' + formData.id, formData, {
                    headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
                });
                if (response.status === 401) {
                    return thunkApi.rejectWithValue(response);
                }
                return response.data;
            } catch (err) {
                console.log(err);
            }
        }
    );

export const editPortFolioCompanySlice = createSlice({
    name: "editPortFolioCompanySlice",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(editPortFolioCompany.fulfilled, (state, action) => {
            state.responseStatus = action.payload;
        });
    },
});

export default editPortFolioCompanySlice.reducer;