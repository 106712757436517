import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

interface CustomSnackbarProps {
    open: boolean;
    message: string;
    handleClose: () => void; // Define handleClose as a function that takes no arguments and returns void
    severity: AlertProps['severity'];
}

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({ open, message, handleClose, severity }) => {
    const handleCloseSnackbar = () => {
        handleClose();
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleCloseSnackbar}
                severity={severity}
            >
                {message}
            </MuiAlert>
        </Snackbar>
    );
};

export default CustomSnackbar;