import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import Button from "@mui/material/Button";
import {
  CircularProgress, Container, Grid, Paper,
  FormControl,
  InputLabel,
} from "@mui/material";
import SelectBox from "../../cst/SelectBox";
import TextBox from "../../cst/TextField";
import { MessageTypeModel } from "../../../types/components/rass/addMessageModel";
import {
  AddMessageText,
  REGPaths,
  MAX_TEXT_LENGTH,
  MAX_LENGTH_PURPOSEOFMESSAGE,
  GenerateMessageCancelDialogMessage,
  Success,
  GenerateMessageSaveSuccessMessage,  
  MessageComponentConstants,  
  Error
} from "../../../utilities/Constants";
import { IdNameModel } from "../../../types/components/idNameModel";
import { getModuleListApi } from "../../../redux/features/rras/regModuleMessage/getModuleListSlice";
import { getPersonaTitleListApi } from "../../../redux/features/rras/regModuleMessage/getPersonaTitleListSlice";
import { getPersonaDataApi } from "../../../redux/features/rras/regModuleMessage/getPersonaDataSlice";
import { PersonaTitleType } from "../../../types/components/rass/personaTitleType";
import { ResponseWithSelectedModelState } from "../../../redux/features/getAllTenantSlice";
import { allTenantDataResultInterface } from "../../../types/pages/LoginApiResponseType";
import Handle401Status from "../../../types/components/handleApiReasponseStatus";
import { PersonaDataType } from "../../../types/components/rass/personaDataType";
import { getGeneratedMessageDataApi } from "../../../redux/features/rras/regModuleMessage/getGeneratedMessageSlice";
import { upsertGeneratedMessageApi  } from "../../../redux/features/rras/regModuleMessage/upsertGeneratedMessageSlice";
import { GenerateMessageRequestModel } from "../../../types/components/rass/genrateMessageReqModel";
import DialogWithTwoButton from "../../cst/DialogWithTwoButton";
import { useFlashMessage } from "../../../redux/flashMessage/flashMessageHook";
import { GenerateMessageUpsertRequestModel } from "../../../types/components/rass/genrateMessageUpsertReqModel";
import { MessageCreatorModel, requestMessageCreatorSearchParams } from "../../../types/components/rass/messageCreatorModel";
import { fetchMessageCreatorList } from "../../../redux/features/rras/regModuleMessage/getMessageCreatorSlice";
import RichTextEditor from "../../richTextEditor/richTextEditor";

export type FormErrors = {
  [key: string]: string;
};

const ViewMessage: React.FC<{ mode?: string }> = ({ mode }) => {
  const { messageCreatorListResponse, loading } = useAppSelector(
    (state) => state.getMessageCreatorList
  );
  const [result, setResult] = useState(messageCreatorListResponse);
  var tenantId = localStorage.getItem("tenantId");
  const { id } = useParams();
  
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<boolean>(true);  
  const [contractTypeErrorFlag ] = useState(false);
  const [tittle, setTittle] = useState<string>(AddMessageText);
  const [errors ] = useState<FormErrors>({});
  const [moduleList, setModuleList] = useState<IdNameModel[]>([]);
  const [personaTitleList, setPersonaTitleList] = useState<IdNameModel[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editorMessageContent, setEditorMessageContent] = useState("");
  const [newEditorMessageContent, setNewEditorMessageContent] = useState("");
  const [isChangedMessageContent, setIsChangedMessageContent] = useState<boolean>(false);
  const [isRegeneratePopupOpen, setIsRegeneratePopupOpen] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [values, setValues] = useState<MessageTypeModel>({    
    id: "",
    tenantId: "",
    moduleName: "",
    personaTitle: "",
    draft: "",
    purposOfMessage: "",
    generatedMessage: "",
    personaData: ""
  });

  const allTenants: ResponseWithSelectedModelState = useAppSelector((state) => {
    return state.getAllTenant;
  });
  const allTenantData = allTenants?.responseModel.result as allTenantDataResultInterface[];
  const getNewSelection = allTenantData.find(
    (el) => el.id === localStorage.getItem("tenantId")
  );
  const [personaParams, setPersonaParams] = useState<PersonaTitleType>({
    companyId: "",
    name:  MessageComponentConstants.personaTitleName,
    tenantId: tenantId ?? "",
  });  
  const { showFlashMessage } = useFlashMessage();
  const [hasAnyChanges , setHasAnyChanges] =useState(false);
  const [hasFormValid , setHasFormValid] =useState(false);  
  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;    
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    switch (name) {
      case "moduleName":
        moduleNameHandler(value);
        break;
      case "personaTitle":
        personaDataHandler(value);
        break;
      default:
        break;
    }    
  };
  useEffect(()=>{
    hasAnyChangesDetected();
  },[values])

  const clearFields = () => {
    setValues((prevState) => ({ ...prevState, personaTitle: "", personaData: "" }));
  }
  const hasAnyChangesDetected =()=>{
    if(result != undefined)
    {    
    const parseResult : MessageCreatorModel  = result[0];
    const hasFormValid = (values.moduleName != "" || values.moduleName.toString() != "0") && (values.personaTitle !="" || values.personaTitle.toString() !="0") && values.purposOfMessage !="" && values.personaData !="" && values.generatedMessage !="";
    setHasFormValid(hasFormValid);
    const hasAnyChanges = values.id != parseResult.id || values.moduleName != parseResult.idCategoryMaster 
        || values.personaTitle != parseResult.idCompanyDetails || values.draft != parseResult.messageDraft
        ||  values.purposOfMessage !=parseResult.purposeOfMessage;

        setHasAnyChanges(hasAnyChanges);        
    }
  }

  const handleCancel = (e: any) => {    
    if (hasAnyChanges) {      
      setIsOpen(true);
    } else {
      navigate(REGPaths.pathMessages)
    }
  };
  const handleReGenerate = () => {    
    try{
    setIsLoading(true);
    setIsRegenerating(true);
    const reqModel: GenerateMessageRequestModel = {
      DraftMessage: values.draft,
      PersonaData: JSON.stringify(values.personaData),
      PurposeOfMessage: values.purposOfMessage,
      TenantId: tenantId ?? "",
      MessageId: id ?? "",
    }
    dispatch(getGeneratedMessageDataApi(reqModel)).then((response) => {
      if (response.payload?.status === 401) {
        Handle401Status(navigate, window);
      }
      else if (response.payload?.status) {
        setValues(prevValue => ({
          ...prevValue,
          generatedMessage: response.payload?.result.MessageGenerated
        })
        )
        setEditorMessageContent(response.payload?.result.MessageGenerated);
        const upsertReqModel: GenerateMessageUpsertRequestModel = {
          Id :values.id,
          MessageDraft: values.draft,
          MessageGenerated: response.payload?.result.MessageGenerated,
          PurposeOfMessage: values.purposOfMessage,
          IdCompanyDetails: values.personaTitle,
          IdCategoryMaster: values.moduleName,
          IdGroup: "",
          TenantId: tenantId ?? "",
          VersionType: MessageComponentConstants.versionTypeAI,
        }
        handleSave(upsertReqModel);
      }
    });
  }
  catch(exception){
    setIsLoading(false);
    setIsRegenerating(false);
    showFlashMessage(MessageComponentConstants.errorMessage, Error, 3000);
  }
  };
  const handleSave =  (upsertReqModel: GenerateMessageUpsertRequestModel) => {
    dispatch(upsertGeneratedMessageApi(upsertReqModel)).then((response) => {
      if (response.payload?.status === 401) {
        Handle401Status(navigate, window);
      }
      else if (response.payload?.status) {
        showFlashMessage(GenerateMessageSaveSuccessMessage, Success, 3000);
        setIsLoading(false);
        setIsRegeneratePopupOpen(false);
        setIsRegenerating(false);
        navigate(REGPaths.pathMessages);
      }
    });
  }
  const moduleNameHandler = (name: string) => {
    const personaParam: PersonaTitleType = {
      companyId: getNewSelection?.seagage_Id,
      name: personaParams.name,
      tenantId: personaParams.tenantId
    }
    if (name !== "0") {
       setIsLoading(true);
      dispatch(getPersonaTitleListApi(personaParam)).then((response) => {
        if (response.payload?.status === 401) {
          Handle401Status(navigate, window);
        }
        else if (response.payload?.status) {
          const personaInfo = response.payload?.result;
          const personaMappedList = personaInfo && personaInfo.map(item => ({ id: item.id, name: item.value }));
          setPersonaTitleList(personaMappedList)
        }
         setIsLoading(false);
      });
    } else {
      clearFields()
      setPersonaTitleList([])
    }
  };
  const personaDataHandler = (value: string) => {
    if (value !== "0") {
      setIsLoading(true);
      const parsonaDataParams: PersonaDataType = {
        CompanyId: getNewSelection?.seagage_Id ?? "",
        Id: value,
        tenantId: tenantId ?? "",
      }
      dispatch(getPersonaDataApi(parsonaDataParams)).then((response) => {
        if (response.payload?.status === 401) {
          Handle401Status(navigate, window);
        }
        else if (response.payload?.status) {
          setValues((prevState) => ({
            ...prevState,
            personaData: (response.payload?.result)
          }));
        }
        setIsLoading(false);
      });
    }
    else {
      setValues((prevState) => ({
        ...prevState,
        personaData: "",
      }));
    }
  };
  useEffect(() => {        
    setIsLoading(true);
    fillDropdownData();
  }, [])

  const fillDataById = (searchParams: requestMessageCreatorSearchParams) => {
    dispatch(fetchMessageCreatorList(searchParams)).then((response) => {
      if (response.payload?.status === 401) {
        Handle401Status(navigate, window);
      }
      else {        
        setResult(response.payload?.result);
        setIsLoading(false);
      }
    });
  }
  const fillDropdownData = () => {
    setPersonaParams((prevState) => ({
      ...prevState,
      ["companyId"]: getNewSelection?.seagage_Id,
    }))
    dispatch(getModuleListApi()).then((moduleListRes) => {
      if (moduleListRes.payload?.status === 401) {
        Handle401Status(navigate, window);
      }
      else if (moduleListRes.payload?.status) {
        const methodList: IdNameModel[] = [{
          id: moduleListRes.payload.data.result.id,
          name: moduleListRes.payload.data.result.name,
        }];
        setModuleList(methodList);
        moduleNameHandler("name");
        const searchParams: requestMessageCreatorSearchParams = {
          tenantId: allTenants.selectedTenant,
          id: id
        }
        fillDataById(searchParams);   
      }
    })
  }
  useEffect (()=>{    
    if(result != undefined)
    {
    const parseResult : MessageCreatorModel  = result[0];
    setValues((prevState) => ({
      ...prevState,  
       id: parseResult.id,       
       purposOfMessage : parseResult.purposeOfMessage,
       moduleName : parseResult.idCategoryMaster,   
       personaTitle : parseResult.idCompanyDetails, 
       generatedMessage :parseResult.messageGenerated,
       draft : parseResult.messageDraft  
    }));
    setEditorMessageContent(parseResult.messageGenerated);
    personaDataHandler(parseResult.idCompanyDetails);
  }
  },[result])
  const getGeneratedMessageContent = (htmlContentProp) => {
    setNewEditorMessageContent(htmlContentProp);
    setValues((prevValues) => ({
      ...prevValues,
      generatedMessage: htmlContentProp,
    }));    
  };
   useEffect(() => {
      if (newEditorMessageContent !== editorMessageContent) {
        setIsChangedMessageContent(true);
      } else {
        setIsChangedMessageContent(false);
      }
    }, [newEditorMessageContent]);

  useEffect(()=>{
    if (mode === "view") {      
        setViewMode(true);
        setTittle(MessageComponentConstants.viewMessagePageTitle);
      }
      else
      {
        setTittle(MessageComponentConstants.editMessagePageitle);
        setViewMode(false);        
      }
    }
  , [mode]);
  const handleEdit = ()=>{
    setTittle(MessageComponentConstants.editMessagePageitle);
        setViewMode(false);          
  }
  const handleRegenerateYesButtonClick = () => {
    handleReGenerate();
    setIsRegeneratePopupOpen(false);    
  };
  const handleRegenerateNoButtonClick = () => {
    setIsRegeneratePopupOpen(false);
  };
  const handleRegenerateDialogClose = () => {    
    setIsRegeneratePopupOpen(false);
  };
  const handleRegeneratePopup = () => {
    setIsRegeneratePopupOpen(true);
  };
  return (
    <div className="container contractTypeContainer">
      <div className="page-title">
        <h1>{tittle}</h1>
      </div>
      <Paper className="add-type-container main-paper" sx={{ pt: 2, pb: 2 }}>
        {isLoading && (
          <div className="loader-center loader-with-text">
            <CircularProgress />
          </div>
        )}
        <div className="">
          <Container maxWidth="xl">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl
                  error={contractTypeErrorFlag}
                  className="contractTypeWrap ml-0"
                  size="small"
                  sx={{ width: "100%" }}
                >
                  <InputLabel
                    id={
                      contractTypeErrorFlag
                        ? "demo-simple-select-error-label"
                        : "demo-simple-select-autowidth-label"
                    }
                  >
                    Module *
                  </InputLabel>
                  <SelectBox
                    name="moduleName"
                    labelId={
                      contractTypeErrorFlag
                        ? "demo-simple-select-error-label"
                        : "simple-select-helper-label"
                    }
                    id={
                      contractTypeErrorFlag
                        ? "demo-simple-select-error"
                        : "simple-select-helper"
                    }
                    label="Select Status"
                    value={values.moduleName}
                    onChange={handleChange}
                    ListData={moduleList}
                    disabled={viewMode}
                    displayEmpty={false}
                    error={!!errors.moduleName}
                    errorText={errors.moduleName}
                    disableUnderLine={false}
                    variant={true}                    
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  error={contractTypeErrorFlag}
                  className="contractTypeWrap ml-0"
                  size="small"
                  sx={{ width: "100%" }}
                >
                  <InputLabel
                    id={
                      contractTypeErrorFlag
                        ? "demo-simple-select-error-label"
                        : "demo-simple-select-autowidth-label"
                    }
                  >
                    Persona Title *
                  </InputLabel>
                  <SelectBox
                    name="personaTitle"
                    labelId={
                      contractTypeErrorFlag
                        ? "demo-simple-select-error-label"
                        : "simple-select-helper-label"
                    }
                    id={
                      contractTypeErrorFlag
                        ? "demo-simple-select-error"
                        : "simple-select-helper"
                    }
                    label="Select Status"
                    value={values.personaTitle}
                    onChange={handleChange}
                    ListData={personaTitleList}
                    disabled={viewMode}
                    displayEmpty={false}
                    error={!!errors.name}
                    errorText={errors.name}
                    disableUnderLine={false}
                    variant={true}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextBox
                  name="purposOfMessage"
                  label="Purpose of Message *"
                  variant="outlined"
                  size="small"
                  value={values.purposOfMessage}
                  onChange={handleChange}
                  error={!!errors.purposOfMessage}
                  helperText={errors.purposOfMessage}
                  className={
                    errors.purposOfMessage ? "errorField rounded-input" : "rounded-input"
                  }
                  maxLength={MAX_LENGTH_PURPOSEOFMESSAGE}
                  disabled={viewMode}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={12}>
                <TextBox
                  name="draft"
                  label="Draft"
                  variant="outlined"
                  size="small"
                  multiline
                  rows={4}
                  maxLength={MAX_TEXT_LENGTH}
                  value={values.draft}
                  onChange={handleChange}
                  error={!!errors.draft}
                  helperText={errors.draft}
                  className={errors.draft ? "errorField" : ""}
                  disabled={viewMode}
                  
                />
              </Grid>
              <Grid item xs={12}>
                <form className="text-editor">
                  <fieldset>
                    <legend>{MessageComponentConstants.richTextBoxTitle}*</legend>
                    <RichTextEditor
                      onChange={getGeneratedMessageContent}
                      initialValue={editorMessageContent}
                      editorTitle={MessageComponentConstants.richTextBoxTitle}
                      readonly={true}
                      enableRowCustomization={true}
                      displayRow={5}
                      isModelEditorReadOnly={true}                      
                      isChangedSummaryContent={isChangedMessageContent}                        
                    />
                    {errors.generatedEmailText && (
                      <div className="text-color-error">
                        This field is required.
                      </div>
                    )}
                  </fieldset>
                </form>
              </Grid>
              <Grid item xs={12} className="form-buttons">
                <Button
                  variant="outlined"
                  className="commoncancelbutton mr-20px"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                {viewMode && <Button
                  variant="contained"
                  className="common-disablebutton mr-20px"
                  onClick={handleEdit}
                >
                  Edit
                </Button>}                
                <Button
                  variant="contained"
                  className="common-disablebutton"
                  onClick={handleRegeneratePopup}
                  disabled={isRegenerating? true : !(hasAnyChanges && hasFormValid)}                >
                  { isRegenerating ? MessageComponentConstants.generatingText : MessageComponentConstants.regeneratedText}                  
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Paper>
      {isOpen && <DialogWithTwoButton
        dialogOpen={true}
        message={GenerateMessageCancelDialogMessage}
        noBtnClick={() => setIsOpen(false)}
        yesBtnClick={() => navigate(REGPaths.pathMessages)}
        dialogClose={() => setIsOpen(false)}
      />}
       <DialogWithTwoButton
                dialogOpen={isRegeneratePopupOpen}
                message={MessageComponentConstants.regenerateDialogMessage}
                noBtnClick={handleRegenerateNoButtonClick}
                yesBtnClick={handleRegenerateYesButtonClick}
                dialogClose={handleRegenerateDialogClose}
              />
    </div>
    
  );
};
export default ViewMessage;