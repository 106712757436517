import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuthProviderConfigEndPoint } from "../../../networking/apiEndpoints";
import { ResponseModel } from "../../../types/components/responseModel";
import { ResponseAuthProviderConfigModel } from "../../../types/components/rass/authProviderConfigModel";

const initialState: ResponseModel = {
    status: false,
    message: "",
    result: null 
};

export const getAuthProviderConfigurations = createAsyncThunk(
    "fetchAuthProviderConfig/fetch",
    async (tenantId : string) => {
        try {                      
            const response = await axios.get(getAuthProviderConfigEndPoint + '/' + tenantId, {
                headers:{"Content-Type": "application/json", Authorization:`Bearer ${localStorage.getItem('token')}`}
              });
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
);

export const getAuthProviderConfigSlice = createSlice({
    name: "authProviderConfig",
    initialState,
    reducers: {       
    },
    extraReducers: (builder) => {
        builder.addCase(getAuthProviderConfigurations.fulfilled, (state, action) => {
            state.status = action.payload?.status;
            state.result = action.payload?.result as ResponseAuthProviderConfigModel [];                        
        });        
    },
});

export default getAuthProviderConfigSlice.reducer;
