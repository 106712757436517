import React, { useEffect, useState } from "react";
import { Box, Paper, Step, StepLabel, Stepper, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppDispatch } from "../../../../redux/store";
import { generateEmailLoaderWithText, REGPaths, VersionTypeAIGenerated } from "../../../../utilities/Constants";
import ContactDetails  from "./ContactDetails";
import SelectTemplates from "./SelectTemplates";
import Button from "@mui/material/Button";
import { getAuthProviderConfigurations } from "../../../../redux/features/rras/getAuthProviderConfigSlice";
import { ResponseAuthProviderConfigModel } from "../../../../types/components/rass/authProviderConfigModel";
import MeetingTranscript from "./ImportFromCalendar";
import { EmailSummaryGenReqModel } from "../../../../types/components/rass/emailSummaryGenReqModel";
import { fetchEmailSummaryGeneratedText } from "../../../../redux/features/rras/getGeneratedEmailSummaryTextSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Handle401Status from "../../../../types/components/handleApiReasponseStatus";
import { FolloUpEmailCreator } from "../../../../types/components/rass/createSummaryTemplateType";
import { userInfoInterface } from "../../../../types/pages/userInfoType";
import { addFollowUpEmailCreator } from "../../../../redux/features/addFollowUpEmailCreator";
import { resetCalendarMeetingList } from "../../../../redux/features/rras/getCalendarMeetingListSlice";

const steps = ['Contacts', 'Transcripts', 'Templates'];

export default function WizardViewTemplate() {
  const location = useLocation();
  const storedData = sessionStorage.getItem('finalMeetingData');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loaderWithText, setLoaderWithText] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(0);
  const [enableFinish, setEnableFinish] = useState(false);
  const [editorEmailContent, setEditorEmailContent] = useState("");  
  const [editorSummaryContent, setEditorSummaryContent] = useState("");
  const [displayGenerationDiv, setDisplayGenerationDiv] = useState(false);
  const [failToSave, setFailToSave] = useState<boolean>(false);
  const [values, setValues] = useState<FolloUpEmailCreator>({
      id: "",
      recipientName: "",
      recipientCompanyName: "",
      transcriptText: "",
      generatedSummaryText: "",
      generatedEmailText: "",
      promptsResponse: [],
      guidancePromptsResponse: "",
      tenantId: "",
      idEmailTemplate: "",
      idSummaryTemplate: "",
      createdBy: "",
      createdOn: new Date().toISOString() ?? "2024-02-23T07:23:18.287Z",
      useSummaryInstead: false,
      summaryOnly: false,
      actualGeneratedSummaryText: "",
      actualGeneratedEmailText: "",
      versionType: "",
    });
  const [contactDetails, setContactDetails] = useState({
    contactName: '',
    companyName: ''
  });
  const [authConfigs, setAuthConfigs] = useState<ResponseAuthProviderConfigModel []>([]);
  const selectedTenantId = localStorage.getItem("tenantId");
  const [transcriptData, setTranscriptData] = useState(""); 
  const [selectedTranscript, setSelectedTranscript] = useState<string>(""); 
  const [selectedTranscriptRadioId, setSelectedTranscriptRadioId] = useState<string | null>(null);
  const [transcriptModeGlobal, setTranscriptModeGlobal] = useState<"imported" | "manual" | null>(null);
  const [importTranscriptButton, setImportTranscriptButton] = useState<boolean>(false);
  const [isTranscriptSelected, setIsTranscriptSelected] = useState<boolean>(false);
  const [transcriptKey, setTranscriptKey] = useState<number>(0);
  const [selectedTemplateRadioId, setSelectedTemplateRadioId] = useState<string | null>(null);
  const useInfo = JSON.parse(
    localStorage.getItem("userInfo") ?? ""
  ) as userInfoInterface;
  // Helper function to persist data in Session storage
  const updateSessionData = (newData: Partial<{
    contactName: string;
    companyName: string;
    transcript: string;
    template: string;
  }>) => {
    const defaultData = {
      contactName: "",
      companyName: "",
      transcript: "",
      template: ""
    };
    let currentData = defaultData;
    if (storedData) {
      try {
        currentData = JSON.parse(storedData).finalMeetingData || defaultData;
      } catch (err) {
        console.error("Error parsing session storage data", err);
      }
    }
    const updatedData = { ...currentData, ...newData };
    sessionStorage.setItem('finalMeetingData', JSON.stringify({ finalMeetingData: updatedData }));
  };

  const handleNext = () => {
    if (activeStep === 0) {
      updateSessionData({
        contactName: contactDetails.contactName,
        companyName: contactDetails.companyName,
      });
    } else if (activeStep === 1) {
      updateSessionData({
        transcript:
          transcriptData.trim().length > 0
            ? transcriptData
            : selectedTranscript,
      });
    } else if (activeStep === 2) {      
      setLoaderWithText(true);
      let finalMeetingData = {
        contactName: "",
        companyName: "",
        transcript: "",
        template: ""
      };
      if (storedData) {
        try {
          finalMeetingData = JSON.parse(storedData).finalMeetingData || finalMeetingData;
        } catch (err) {
          console.error("Error parsing session storage data", err);
        }
      }
      const emailSummaryGenReqModel: EmailSummaryGenReqModel = {
        id: finalMeetingData.template,
        transcript: finalMeetingData.transcript,
        recipientName: finalMeetingData.contactName,
        recipientCompanyName: finalMeetingData.companyName,
        isSummaryTemplateProvided: false,
        summaryOnly: false,
        userName : useInfo.Name?.toLowerCase() ?? "",      
        portcoName : "",
        tenantId : selectedTenantId ?? "",
        emailCreatorId : "",
      };

      dispatch(fetchEmailSummaryGeneratedText(emailSummaryGenReqModel)).then(
        (response) => {
          if (response.payload?.status === 401) {
            setLoaderWithText(false);
            Handle401Status(navigate, window);
          }
          if (!response.payload) {
            setLoaderWithText(false);
          } else {
            setDisplayGenerationDiv(true);
            setEditorEmailContent(response.payload.result.generatedEmailText);
            setEditorSummaryContent(response.payload.result.generatedSummaryText);
            values.generatedSummaryText = response.payload.result.generatedSummaryText;
            values.generatedEmailText = response.payload.result.generatedEmailText;
            values.promptsResponse = response.payload.result.promptsResponse;
            values.guidancePromptsResponse = response.payload.result.guidancePromptsResponse;
            values.versionType = VersionTypeAIGenerated;
            setValues((prevValues) => ({
              ...prevValues,
              ["generatedSummaryText"]: response.payload.result.generatedSummaryText,
              ["generatedEmailText"]: response.payload.result.generatedEmailText,
              ["promptsResponse"] : response.payload.result.promptsResponse,
              ["guidancePromptsResponse"] : response.payload.result.guidancePromptsResponse,
            }));
            handleSubmit();
          }
        }
      );
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setEnableFinish(false);
    let finalMeetingData = {
        contactName: "",
        companyName: "",
        transcript: "",
        template: ""
      };
    if (storedData) {
      try {
        finalMeetingData = JSON.parse(storedData).finalMeetingData || finalMeetingData;
      } catch (err) {
        console.error("Error parsing session storage data", err);
      }
    }
    values.tenantId = selectedTenantId ?? "";
    values.createdBy = useInfo.Name?.toLowerCase() ?? "";
    values.useSummaryInstead = false;
    values.summaryOnly = false;
    values.recipientCompanyName=finalMeetingData.companyName;
    values.recipientName=finalMeetingData.contactName;
    values.idEmailTemplate=finalMeetingData.template;
    values.transcriptText= finalMeetingData.transcript;
    dispatch(addFollowUpEmailCreator(values)).then((response) => {
      setLoaderWithText(false);
      setIsLoading(false);
      setEnableFinish(true);
      if (response.payload?.status === 401) {
        Handle401Status(navigate, window);
      }
      if (!response.payload) {
        setFailToSave(true);
      } else {
        setIsLoading(false);
        const isInitialCreate = true;
        navigate(`${REGPaths.pathViewEmail}/${response.payload.result}`, { state: { isInitialCreate } });
      }
    });
  };

  useEffect(() => {
    dispatch(getAuthProviderConfigurations(selectedTenantId ?? "")).then((response) => {      
      setAuthConfigs(response.payload?.result || []);
    });
  }, [dispatch, selectedTenantId]);


  const handleBack = () => {
    if (activeStep === 2) {
      setActiveStep(1);
      return;
    }
    if (activeStep === 1 && transcriptModeGlobal !== null) {
      if (transcriptModeGlobal === "manual") {
        setTranscriptModeGlobal(null);
        setIsTranscriptSelected(false);
        setImportTranscriptButton(false);
        dispatch(resetCalendarMeetingList());
      } else if (transcriptModeGlobal === "imported") {
        setTranscriptModeGlobal(null);
        setImportTranscriptButton(false);
      }
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleContactDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setContactDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
    updateSessionData({ [name]: value });
  };

  const isContactDetailsValid = () => {
    return contactDetails.contactName.trim() !== '' && contactDetails.companyName.trim() !== '';
  };

  const computedTranscript = transcriptData.trim().length > 0 ? transcriptData : selectedTranscript;

  const getStepContent = (stepLabel: string) => {
    const stepContentMap: Record<string, JSX.Element> = {
      Contacts: <ContactDetails contactDetails={contactDetails} onChange={handleContactDetailsChange} />,
      Transcripts: (
        <MeetingTranscript
          authConfigs={authConfigs}
          isImportTranscript={setIsTranscriptSelected}
          isImportButtonClicked={importTranscriptButton}
          externalSelectedRadioId={selectedTranscriptRadioId}
          onRadioSelect={setSelectedTranscriptRadioId}
          onTranscriptModeChange={setTranscriptModeGlobal}
          onTranscriptManualChange={(text: string) => {
            setTranscriptData(text);
          }}
          onTranscriptChange={(text: string) => {
            setSelectedTranscript(text);
          }}
          initialTranscript={computedTranscript}
          currentTranscriptMode={transcriptModeGlobal}
          onResetTranscript={() => {
            setTranscriptData("");
            setSelectedTranscript("");
            setSelectedTranscriptRadioId(null);
            setIsTranscriptSelected(false);
            setImportTranscriptButton(false);
            setTranscriptModeGlobal(null);
          }}
        />
      ),
      Templates: <SelectTemplates onRadioSelect={setSelectedTemplateRadioId} 
      externalSelectedRadioId={selectedTemplateRadioId}/>,
    };

    return stepContentMap[stepLabel];
  };

  useEffect(() => {
    dispatch(resetCalendarMeetingList());
  }, [location.pathname, dispatch]);

  return (
    <Paper className="main-paper" sx={{ pt: 2, pb: 2, overflowX: 'hidden' }}>
      {isLoading && (
        <div className="loader-center loader-with-text">
          <CircularProgress />
          {loaderWithText && (
            <h3 className="loader-message">{generateEmailLoaderWithText}</h3>
          )}
        </div>
      )}
      <Box sx={{ width: '96%', margin:'0 auto', overflowX: 'hidden'}}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }} className="calendar-wrapper">
            <div className="loader-center loader-with-text">
            <CircularProgress />
              {loaderWithText && (
                <h3 className="loader-message">{generateEmailLoaderWithText}</h3>
              )}
            </div>
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {!loaderWithText && (
              <Button
                variant="contained"
                className="common-disablebutton"
                disabled={activeStep === 0}
                onClick={handleBack}
              >Back</Button>
              )}
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {getStepContent(steps[activeStep])}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              {activeStep !== 0 && (
                <Button
                  variant="contained"
                  className="common-disablebutton"
                  disabled={activeStep === 0}
                  onClick={handleBack}     
                >Back</Button>
              )}
              <Box sx={{ flex: '1 1 auto' }} />
              {activeStep === 1 && (
                <Button
                  variant="contained"
                  className="common-disablebutton"
                  onClick={() => setImportTranscriptButton(true)}
                  sx={{ mr: 1 }}
                  disabled={!isTranscriptSelected || importTranscriptButton}
                >
                  Import Transcript
                </Button>
              )}
              <Button
                variant="contained"
                className="common-disablebutton"
                onClick={handleNext}
                disabled={
                  activeStep === 0
                    ? !isContactDetailsValid()
                    : activeStep === 1
                    ? transcriptModeGlobal === null
                    : !selectedTemplateRadioId 
                    ? !enableFinish
                    :false
                }
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </Paper>
  );
}
