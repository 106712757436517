import { Grid } from "@mui/material";
import TextBox from "../../../cst/TextField";

interface ContactDetailsProps {
  contactDetails: {
    contactName: string;
    companyName: string;
  };
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({ contactDetails, onChange }) => {
  return (
    <div className="calendar-wrapper contact">
      <h2>Enter Prospects</h2>
      <div className="text-field-space">
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <TextBox
              name="contactName"
              id="outlined-contact-name"
              label="Contact Name *"
              value={contactDetails.contactName}
              maxLength={150}
              onChange={onChange}
              type="field"
              size="small"
              variant="outlined"
              className={'rounded-input'}
            />
          </Grid>
        </Grid>
      </div>
      <div className="text-field-space">
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <TextBox
              name="companyName"
              id="outlined-company-name"
              label="Company Name *"
              value={contactDetails.companyName}
              maxLength={150}
              onChange={onChange}
              type="field"
              size="small"
              variant="outlined"
              className={'rounded-input'}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ContactDetails;