import { PublicClientApplication } from "@azure/msal-browser";

const loginUri = "https://login.microsoftonline.com";
const redirectUri = process.env.REACT_APP_UI_ENDPOINT;
const scopes = ["User.Read"];


export const getMSALInstance = (clientId, tenantId) => {
    const msalConfig = {
        auth: {
            clientId: clientId,
            authority: `${loginUri}/${tenantId}`,
            redirectUri: redirectUri,
        }
    }
    return new PublicClientApplication(msalConfig);
}

export const loginWithPopup = async (msalInstance: PublicClientApplication) => {
    try {
      const response = await msalInstance.loginPopup({scopes: scopes});
      return response.accessToken;
  } catch (error) {
      console.log("Token acquisition failed:"  +error);
      return null;
  }
};