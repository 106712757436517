import WizardViewTemplate from "../../../../components/rras/CreateEmail/WizardViewTemplate/WizardViewTemplate";

export const WizardMeeting: React.FC<{ mode?: string }> = ({ mode }) => {
    return (
      <div className="container createEmail">
        <div className="page-title">
          <h1>Create Email & Summary</h1>
        </div>
        <WizardViewTemplate />
      </div>
    )
  }
  
  export default WizardMeeting;