import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { getSalesTrainingInsightReportsEndPoint } from "../../../networking/apiEndpoints";
import { SaleTrainingInsightsReportModal } from "../../../types/components/rass/saleTrainingInsightsReportModal";
import { salesTrainingReportPrompts } from "../../../types/components/rass/salesTrainingPromptReportModel";

export const fetchSalesTrainingInsightsList = createAsyncThunk<
AxiosResponse,
any,
  {
  rejectValue:AxiosResponse
}
>(
    "salesTrainingInsightsList/fetch", 
    async (searchSalesTrainingPrompts: salesTrainingReportPrompts, thunkApi) => {
            var token = localStorage.getItem('token');
            const response = await axios.post(getSalesTrainingInsightReportsEndPoint, searchSalesTrainingPrompts, {
             headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },validateStatus: function(status){return status < 500;}
        });
        if (response.status === 401) {
          return thunkApi.rejectWithValue(response);
        }
            return response;       
    }
);

interface SaleTrainingInsightsReport {
    data: SaleTrainingInsightsReportModal[] | undefined;
    loading: boolean;
    error: string | null;
    responseStatus: number;
}

const initialState: SaleTrainingInsightsReport = {
    data: [],
    loading: false,
    error: null,
    responseStatus: 200
};

export const fetchSalesTrainingInsightsListSlice = createSlice({
    name: "fetchSalesTrainingInsightsList",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSalesTrainingInsightsList.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(fetchSalesTrainingInsightsList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload.data;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
            })
            .addCase(fetchSalesTrainingInsightsList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? '';
                if (action.payload !== undefined) {
                    state.responseStatus = action.payload.status;
                }
            });
    },
});
export default fetchSalesTrainingInsightsListSlice.reducer;