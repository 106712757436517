import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getDesignationList } from "../../networking/apiEndpoints";
import { DesignationModel } from "../../types/components/staffingModel.";

export const fetchDesignationList = createAsyncThunk(
    "designationModel/fetch",
    async (_, thunkApi) => { 

        try {
            var token = localStorage.getItem('token');
            const response = await axios.get(getDesignationList, {
                headers: {Authorization:`Bearer ${token}`}
            });
            if (response.status === 401) {
                return thunkApi.rejectWithValue(response);
            }
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
);

interface DesignationState {
    data: DesignationModel[] | undefined;
    loading: boolean;
    error: string | null;
    responseStatus:number;
}

const initialState: DesignationState = {
    data: [],
    loading: false,
    error: null,
    responseStatus:200
};

export const designationListSlice = createSlice({
    name: "designationlist",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDesignationList.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(fetchDesignationList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
            })
            .addCase(fetchDesignationList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? '';
                if(action.payload !== undefined)
                    {
                      state.responseStatus = 401;
                    }
            });
    },
});
export default designationListSlice.reducer;