/* -----------------Meeting Page Permissions------------------------ */
export const AddNewMeetingPermission = "Add New Meeting";
export const ViewEmailDashboardPermission = "View Meetings Dashboard";
export const EditEmailAndSummaryPermission = "Edit Email & Summary";
export const DeleteEmailAndSummaryPermission = "Delete Email & Summary";
/* -----------------End of Meeting Page Permissions--------------------- */

/* -----------------Start REG Left Menu Permissions------------------------ */
export const NavREGMeetingDashboard = "View Meetings Dashboard";
export const NavREGSummaryTemplateDashboard = "View Summary Templates Dashboard";
export const NavREGEmailTemplateDashboard = "View Email Templates Dashboard";
export const NavREGUserDashboard = "View Users Dashboard";
export const NavREGRolesPermissionDashboard = "View Roles & Permissions Dashboard";
export const NavREGSalesAndMarkettingDataSync = "Sync Data Dashboard";
export const NavREGAIMessages = "View Messages Dashboard";
export const NAVREGUserProfileInformationPermission = "Profile Information Dashboard";
export const NavREGSalesTrainingInsightsReport = "Generate Sales Training Insights Report";
/* -----------------End REG Left Menu Permissions------------------------ */

/* -----------------Start CST Left Menu Permissions------------------------ */
export const NavCSTContractsDashboard = "View Contracts Dashboard";
export const NavCSTPreProcessingContractsDashboard = "View Pre-Processing Contracts Dashboard";
export const NavCSTContractsTypesDashboard = "View Contract Types Dashboard";
export const NavCSTContractsTermsDashboard = "View Contract Terms Dashboard";
export const NavCSTUserFeedbackReport = "Generate Users Feedback Report";
export const NavCSTGenerateContractsReport = "Generate Contracts Report";
export const NavCSTUsersDashboard = "View Users Dashboard";
export const NavCSTRolesPermissionDashboard = "View Roles & Permissions Dashboard";
export const NavCSTViewScreeningTermsDashboardPermission = "View Screening Terms Dashboard";
export const NAVCSTUserProfileInformationPermission = "Profile Information Dashboard";
/* -----------------End CST Left Menu Permissions------------------------ */

/* -----------------Summary Template Page Permissions------------------------ */
export const AddNewSummaryTemplatePermission = "Create Custom Summary Template";
export const ViewSummaryTemplateDashboardPermission = "View Summary Templates Dashboard";
export const EditSummaryTemplatePermission = "Edit Custom Summary Template";
export const DeleteSummaryTemplatePermission = "Delete Custom Summary Template";    
/* -----------------End of Summary Template Page Permissions--------------------- */

/* -----------------CST- Contract Type Page Permissions------------------------ */
export const AddNewContractTypePermission = "Create Contract Types";
export const ViewContractTypeDashboardPermission = "View Contract Types Dashboard";
export const EditContractTypePermission = "Edit Contract Types";
export const DeleteContractTypePermission = "Delete Contract Types";    
/* -----------------End of Contract Type Page Permissions--------------------- */

/* -----------------Email Template Page Permissions------------------------ */
export const CreateCustomEmailTemplatePermission = "Create Custom Email Template";
export const ViewEmailTemplatesDashboardPermission = "View Email Templates Dashboard";
export const EditCustomEmailTemplatePermission = "Edit Custom Email Template";
export const DeleteCustomEmailTemplatePermission = "Delete Custom Email Template";
/* -----------------End of Meeting Page Permissions--------------------- */

/* -----------------Contracts Terms------------------------ */
export const CreateContractsTermsPermission = "Create Contract Terms";
export const ViewContractsTermsDashboardPermission = "View Contract Terms Dashboard";
export const EditContractsTermsPermission = "Edit Contract Terms";
export const DeleteContractsTermsPermission = "Delete Contract Terms";
/* -----------------End of Contracts Terms--------------------- */

/* -----------------Reports Page Permissions------------------------ */
export const GenerateContractsReportPermission = "Generate Contracts Report";
export const GenerateUsersFeedbackReportPermission = "Generate Users Feedback Report";
/* -----------------End of Reports Page Permissions--------------------- */

/* -----------------Contract Page Permissions------------------------ */
export const DownloadCSVReportPermission = "Download CSV Report";
export const UploadContractsPermission = "Upload Contracts";
export const ReanalyzeContractsPermission = "Reanalyze Contracts";
export const SetContractPrecedenceOrderPermission = "Set Contract Precedence Order";
export const AttachContractsPermission = "Attach Contracts";
export const RemoveLinkedContractsPermission = "Remove Linked Contracts";
export const ViewContractsDashboardPermission = "View Contracts Dashboard";
export const DeleteContractsPermission = "Delete Contracts";
export const ProvideFeedbackPermission = "Provide Feedback";
export const DownloadContractPermission = "Download Contract";
/* -----------------End of Contract Page Permissions--------------------- */

/* -----------------User Page Permissions------------------------ */
export const ViewUsersDashboardPermission = "View Users Dashboard";
export const EditUsersPermission = "Edit Users";
export const ActivateInactivateUnlockUsersPermission = "Activate/Inactivate/Unlock Users";
export const AddUserPermission = "Add User";
/* -----------------End of User Page Permissions--------------------- */

/* -----------------Roles & Permission Page Permissions------------------------ */
export const ViewRolesDashboardPermission = "View Roles & Permissions Dashboard";
export const EditRolePermission = "Edit Role & Permissions";
export const DeleteRolePermission = "Delete Role & Permissions";
export const CreateRolesPermission = "Create Roles & Permissions";
/* -----------------Roles & Permission Page Permissions--------------------- */

/* -----------------User Profile Information Page Permissions------------------------ */
export const ViewMyProfilePermission = "View My Profile Dashboard";
export const EditMyProfilePermission = "Edit My Profile"; 
export const ChangePasswordPermission = "Change Password"; 
/* -----------------User Profile Information Page Permissions--------------------- */

/* -----------------Screening Terms Page Permissions------------------------ */
export const ViewScreeningTermsDashboardPermission = "View Screening Terms Dashboard";
export const AddScreeningTermsPermission = "Add Screening Terms";
export const EditScreeningTermsPermission = "Edit Screening Terms";
export const DeleteScreeningTermsPermission = "Delete Screening Terms";
/* -----------------Screen Terms Page Permissions--------------------- */

/* -----------------AI Messages Page Permissions------------------------ */
export const CreateAIMessagePermission = "Create Messages";
export const EditAIMessagePermission = "Edit Messages";
export const DeleteAIMessagePermission = "Delete Messages";
/* -----------------AI Messages Page Permissions--------------------- */

/* -----------------Pre-Processing Page Permissions------------------------ */
export const MovePreProcessPermission = "Move Pre-Processing Contracts";
export const DeletePreProcessPermission  = "Delete Pre-Processing Contracts";
export const RePreProcessPermission = "Reprocess Contracts";
/* -----------------Pre-Processing Page Permissions------------------------ */

/* -----------------Sales & Marketing Data Sync Page Permissions------------------------ */
export const SyncDataDashboard = "Sync Data Dashboard";
/* -----------------Sales & Marketing Data Sync Page Permissions--------------------- */

/* -----------------Access Self Data Permissions------------------------ */
export const AccessSelfData = "Access Self Data";
/* -----------------Access Self Data Permissions--------------------- */

/* -----------------Sales Training Prompts Page Permissions------------------------ */
export const CreateSalesTrainingPromptsPermission = "Create Sales Training Prompts";
export const EditSalesTrainingPromptsPermission = "Edit Sales Training Prompts";
export const DeleteSalesTrainingPromptsPermission = "Delete Sales Training Prompts";
/* -----------------Sales Training Prompts Page Permissions--------------------- */

/* -----------------Start STAFFING Left Menu Permissions------------------------ */
export const NavStaffingDashboard = "View Staffing Dashboard"; 
export const NavStaffingEmployeeListDashboard = "Employee List Dashboard";
export const NavStaffingEditEmployee = "Edit Employee";

export const NavStaffingDesignationListDashboard = "Designation List Dashboard";
export const NavStaffingAddDesignation = "Add Designation";
export const NavStaffingEditDesignation = "Edit Designation";
export const NavStaffingDeleteDesignation = "Delete Designation";

export const NavStaffingBillingDesignationListDashboard = "Billing Designation List Dashboard";
export const NavStaffingAddBillingDesignation = "Add Billing Designation";
export const NavStaffingEditBillingDesignation = "Edit Billing Designation";
export const NavStaffingDeleteBillingDesignation = "Delete Billing Designation";

export const NavStaffingPortfolioCompanyListDashboard = "Portfolio Company List Dashboard";
export const NavStaffingAddPortfolioCompany = "+ Portfolio Company";
export const NavStaffingEditPortfolioCompany = "Edit Portfolio Company";
export const NavStaffingDeletePortfolioCompany = "Delete Portfolio Company";

export const NavStaffingEmployeeProjectListDashboard = "Employee Project List Dashboard";
export const NavStaffingAddEmployeeProject = "Add Employee Project";
export const NavStaffingEditEmployeeProject = "Edit Employee Project";
export const NavStaffingDeleteEmployeeProject = "Delete Employee Project";

export const NavStaffingUsersDashboard = "View Users Dashboard";
export const NavStaffingRolesPermissionDashboard = "View Roles & Permissions Dashboard";
/* -----------------End STAFFING Left Menu Permissions------------------------ */
/* -----------------Staffing- PortFolioCompany Permissions------------------------ */
export const AddPortFolioCompanyPermission = "Create PortfolioCompany";
export const ViewPortFolioCompanyDashboardPermission = "View PortFolioCompany Dashboard";
export const EditPortFolioCompanyPermission = "Edit Portfolio Company";
/* -----------------End of PortFolioCompany Page Permissions--------------------- */



/* -----------------Staffing- Designation Permissions------------------------ */
export const AddDesignationPermission = "Create Designation";
export const ViewDesignationDashboardPermission = "View Designation Dashboard";
export const EditDesignationPermission = "Edit Designation";
/* -----------------End of Designation Page Permissions--------------------- */

/* -----------------Staffing- Designation Permissions------------------------ */
export const AddBillingDesignationPermission = "Create Billing Designation";
export const ViewBillingDesignationDashboardPermission = "View Billing Designation Dashboard";
export const EditBillingDesignationPermission = "Edit Billing Designation";
/* -----------------End of Designation Page Permissions--------------------- */

/* -----------------Sales Training Prompts Page Permissions------------------------ */
export const ViewSalesTrainingPromptsPermission = "View Sales Training Prompts Dashboard";
export const CreateSalesTrainingPromptPermission = "Create Sales Training Prompts";
export const EditSalesTrainingPromptPermission = "Edit Sales Training Prompts";
export const DeleteSalesTrainingPromptPermission = "Delete Sales Training Prompts";
/* -----------------End of Meeting Page Permissions--------------------- */

