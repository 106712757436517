import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { addPortfolioCompany } from "../../networking/apiEndpoints";
import { PortFolioCompanyModelState } from "../../types/components/staffingModel.";

const initialState: PortFolioCompanyModelState = {
    portfolioCompanyModel: [],
    loading: false,
    error: null,
    showAlert: false,
    responseStatus: 200
};

//Add PortFolioCompany
export const addPortFolioCompanyName = createAsyncThunk<
    AxiosResponse | any,
    any,
    {
        rejectValue: AxiosResponse;
    }>(
        "addportfoliocompanyname/post",
        async (formData: any, thunkApi) => {
            try {
                var token = localStorage.getItem('token');
                const response = await axios.post(addPortfolioCompany, formData, {
                    headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
                });
                if (response.status === 401) {
                    return thunkApi.rejectWithValue(response);
                }
                return response.data;
            } catch (err) {
                console.log(err);
            }
        }
    );

export const addPortFolioCompanySlice = createSlice({
    name: "addPortFolioCompanySlice",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(addPortFolioCompanyName.fulfilled, (state, action) => {
            state.responseStatus = action.payload;
        });
    },
});

export default addPortFolioCompanySlice.reducer;