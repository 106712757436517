import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { CircularProgress, Container, Grid, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import CustomSnackbar from "../../cst/Snackbar";
import DialogWithTwoButton from "../../cst/DialogWithTwoButton";
import TextBox from "../../cst/TextField";
import { getPermissionsFromToken } from "../../../utilities/getPermissionsFromToken";
import { AddBillingDesignationPermission, EditBillingDesignationPermission } from "../../../utilities/PermissionConstants";
import {
  DiscardChangesDialogMessage,
  FailedDuplicateCheck,
  RequiredFields,
  BillingDesignationExists,
  FailToSaveBillingDesignation,
  PathBillingDesignationList,
} from "../../../utilities/Constants";
import Handle401Status from "../../../types/components/handleApiReasponseStatus";
import { ValidationRule } from "../../../types/components/validationType";
import { BillingDesignationModel } from "../../../types/components/staffingModel.";
import { BillingDesignationFilterModel } from "../../../types/components/staffingFilterModel";
import { BillingDesignationCheckDuplicateModel } from "../../../types/CheckDuplicateStaffingModel";
import { userInfoInterface } from "../../../types/pages/userInfoType";
import { checkDuplicateBillingDesignation } from "../../../redux/features/checkDuplicateBillingDesignationSlice";
import { getBillingDesignationById } from "../../../redux/features/getBillingDesignationByIdSlice";
import { addBillingDesignationName } from "../../../redux/features/addBillingDesignationSlice";
import { editBillingDesignation } from "../../../redux/features/editBillingDesignationSlice";
export type FormErrors = {
  [key: string]: string;
};

const AddBillingDesignation: React.FC<{ mode?: string; itemId?: string }> = ({
  mode,
  itemId,
}) => {
  const theme = useTheme();
  const [values, setValues] = useState<BillingDesignationModel>({
    id: "",
    tenantid: "",
    name: "",
    createdon: new Date().toISOString() ?? "2024-02-23T07:23:18.287Z",
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [initialBillingDesignationName, setInitialBillingDesignationName] =
    useState<string>("");
  const [isChanged, setIsChanged] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [tittle, setTittle] = useState<string>("Add Billing Designation");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const addBillingDesignationDispatch = useAppDispatch();
  var selectedTenantId = localStorage.getItem("tenantId");
  const [editBillingDesignationPermissionNoAccess, setEditBillingDesignationPermissionNoAccess] = useState<boolean>(true);
  const [addBillingDesignationPermissionNoAccess, setAddBillingDesignationPermissionNoAccess] = useState<boolean>(true);

  const billingdesignationId: string = itemId ?? "";

  const useInfo = JSON.parse(
    localStorage.getItem("userInfo") ?? ""
  ) as userInfoInterface;

  const userId = useInfo.Id ?? "";

  useEffect(() => {
    const userPermissions = getPermissionsFromToken();
    if (mode === "edit") {
      setIsLoading(true);
      setTittle("Edit Billing Designation");
      fetchBillingDesignation(billingdesignationId);
      setInitialBillingDesignationName(values.name ? values.name : "");
      setIsLoading(false);
    }
  }, [mode]);


  function generate_uuid() {
    var dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
      function (c) {
        var rnd = Math.random() * 16;//random number in range 0 to 16
        rnd = (dt + rnd) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? rnd : (rnd & 0x3 | 0x8)).toString(16);
      });
  }


  const fetchBillingDesignation = async (billingdesignationId: string) => {
    addBillingDesignationDispatch(getBillingDesignationById(billingdesignationId)).then((response) => {
      const billingdesignation = response.payload?.billingdesignation;
      const tenantId = localStorage.getItem("tenantId");
      setValues({
        id: billingdesignation.id,
        name: billingdesignation.name,
        tenantid: tenantId ?? "",
        createdby: billingdesignation.createdby,
        createdon: billingdesignation.createdon,
        modifiedby: billingdesignation.modifiedby,
        modifiedon: billingdesignation.modifiedon,
      });
      setInitialBillingDesignationName(billingdesignation.name);
    });
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const isChangeDetected = value !== initialBillingDesignationName;
    setIsChanged(isChangeDetected); // Check if value changed
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));


    if (name === "name" && isChangeDetected) {
      try {
        const tenantId = localStorage.getItem("tenantId");
        const parm: BillingDesignationCheckDuplicateModel = {
          id: values.id,
          name: value,
          tenantId: tenantId ?? "",
        }
        addBillingDesignationDispatch(checkDuplicateBillingDesignation(parm)).then(
          (response) => {
            if (response.payload?.status === 401) {
              Handle401Status(navigate, window);
            }
            const isDuplicate = Boolean(response.payload == "false" ? false : true);
            setIsDuplicate(isDuplicate);
            setErrors((prevErrors) => ({
              ...prevErrors,
              [name]: isDuplicate ? BillingDesignationExists : "",
            }));
          }
        );
      } catch (error) {
        handleSnackbarOpen(FailedDuplicateCheck);
      }
    }
  };

  const validateForm = () => {
    const newErrors: FormErrors = {};
    Object.entries(values).forEach(([key, value]) => {
      const rules: ValidationRule = {
        required: true,
        maxLength: key === "name" ? 40 : 250,
      };
      if (rules.required && key === "name" && ((value != null || value == "") && !value.trim())) {
        newErrors["name"] = RequiredFields;
      }

      if (
        rules.maxLength && (key === "name") && (value != null &&
          value.length > rules.maxLength)
      ) {
        newErrors[
          key
        ] = `Maximum length exceeded (${rules.maxLength} characters max)`;
      }
      if (isDuplicate) {
        newErrors["name"] = BillingDesignationExists;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0 && !isDuplicate;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    setIsLoading(true);
    const errormsg = true;
    try {
      if (mode === "edit") {
        values.modifiedby = userId;
        values.id = billingdesignationId;
        values.modifiedon = new Date().toISOString() ?? "2024-02-23T07:23:18.287Z";
        addBillingDesignationDispatch(editBillingDesignation(values)).then((response) => {
          if (!response.payload) {
            handleSnackbarOpen(FailToSaveBillingDesignation);
          } else {
            navigate(PathBillingDesignationList);
          }
        });
      }
      else {
        const tenantId = localStorage.getItem("tenantId");
        values.tenantid = tenantId!;
        values.id = generate_uuid();
        values.createdby = userId;
        addBillingDesignationDispatch(addBillingDesignationName(values)).then((response) => {
          if (!response.payload) {
            handleSnackbarOpen(FailToSaveBillingDesignation);
          } else {
            navigate(PathBillingDesignationList);
          }
        });
      }

    } catch (error) {
      handleSnackbarOpen(FailToSaveBillingDesignation, "error");
    }
  };

  const handleYesButtonClick = () => {
      setisOpen(false);
      navigate(PathBillingDesignationList);
    };

  const handleSnackbarOpen = (
    message: string,
    severity: "success" | "error" = "error"
  ) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };
  const handleNoButtonClick = () => {
    setisOpen(false);
  };
  const handleDialogClose = () => {
    setisOpen(false);
  };
  // Function to handle cancel button click
  const handleCancel = () => {
    // If text has been changed, show confirmation dialog
    if (isChanged) {
      setisOpen(true);
    } else {
      navigate(PathBillingDesignationList);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="container  contractTypeContainer">
      <div className="page-title">
        <h1>{tittle}</h1>
      </div>
      <Paper className="add-type-container main-paper" sx={{ pt: 2, pb: 2 }}>
        {isLoading && (
          <div className="loader-center">
            <CircularProgress />
          </div>
        )}
        <div className="">
          <Container maxWidth="xl">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextBox
                  name="name"
                  label="Billing Designation Name *"
                  variant="outlined"
                  size="small"
                  value={values.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                  className={
                    errors.name ? "errorField rounded-input" : "rounded-input"
                  }
                  maxLength={50}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={12} className="form-buttons">
                <Button
                  variant="outlined"
                  className="commoncancelbutton mr-20px"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="common-disablebutton"
                  onClick={handleSubmit}
                  disabled={!values.name || isLoading || !isChanged || isDuplicate}
                >
                  {isLoading ? "Saving..." : "Save"}

                </Button>
              </Grid>

              <CustomSnackbar
                open={openSnackbar}
                message={snackbarMessage}
                handleClose={handleSnackbarClose}
                severity={snackbarSeverity}
              />
              <DialogWithTwoButton
                dialogOpen={isOpen}
                message={DiscardChangesDialogMessage}
                noBtnClick={handleNoButtonClick}
                dialogClose={handleDialogClose}
                yesBtnClick={handleYesButtonClick}
              />
            </Grid>
          </Container>
        </div>
      </Paper>
    </div>
  );
};
export default AddBillingDesignation;
