import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import {
  Data,
  HeadCell,
  MainTableProps,
} from "../../../types/components/contractTypeModel";
import { EnhancedTableHead } from "./ListViewHeader";
import { ContractTerm } from "../../../types/components/contractTypeModel";
import { GetTransData } from "../../../utilities/sortUtilities";
import {
  Avatar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Pagination,
  Radio,
  Switch,
} from "@mui/material";
import { UTCToLocalTimeZone } from "../../../utilities/CommonFunctions";
import {
  AttachFileOutlined,
  DeleteOutlineOutlined,
  EditNoteOutlined,
  QueryStats,
  MoreVert,
  VisibilityOutlined,
  MoveUpOutlined,
} from "@mui/icons-material";
import { ContractListId } from "../../../types/components/contractDetailsTypes";
import { AllowedCompletedStatus, AllowedReanalyzeStatus, AllowedRoleStatus, ColumnNameType } from "../../../utilities/Constants";
import PersonOffIcon from '@mui/icons-material/PersonOff';

export default function EnhancedTable<T>(props: MainTableProps<T>) {
  const {
    Data,
    column,
    handleEdit,
    handleView,
    handleMove,
    handleReprocess,
    enableCheckBox,
    enableRadioButton,
    enableRadioButtonTemplate,
    handleDelete,
    handleReanalyze,
    editDisabled,
    deleteDisabled,
    handleToggleClick,
    handleSingleSelect,
    handleRadioSelect,
    handleRadioSelectTemplate,
    handleMultiSelect,
    handleDeleteMultiSelect,
    handleAttachContracts,
    rowsPerTable,
    handleUnassign,
    externalSelectedRadioId,
    selectedCheckboxIds = [],
    isDisable=false
  } = props;
  type Order = "asc" | "desc";


  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }

    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  const anydata = Data as ContractTerm[];
  const columnNew = column as HeadCell<T>[];
  const rows = GetTransData(anydata, columnNew);
  const headCells: HeadCell<T>[] = columnNew;
  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data<T>>("name");
  const [selected, setSelected] = React.useState<readonly string[]>(selectedCheckboxIds);
  const [page, setPage] = React.useState(1);
  const perPage = 25;
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerTable ?? 25);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);
  const [selectedRadio, setSelectedRadio] = React.useState<string | null>(null);

  const effectiveSelectedRadio =
  typeof externalSelectedRadioId !== 'undefined' && externalSelectedRadioId !== null
    ? externalSelectedRadioId
    : selectedRadio;

  const open = Boolean(anchorEl);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data<T>
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleRadioButtonSelect = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if (typeof externalSelectedRadioId === 'undefined' || externalSelectedRadioId === null) {
      setSelectedRadio(id);
    }
    if (handleRadioSelect) {
      handleRadioSelect(id, true);
    }
    if (handleRadioSelectTemplate) {
      handleRadioSelectTemplate(id, true);
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      const selectedIds: ContractListId[] = [];
      rows.forEach((x) => {
        let item: ContractListId = {
          id: x.toString(),
          statusText: x.ter,
        };
        selectedIds.push(item);
      });

      setSelected(newSelected);
      if (handleMultiSelect) {
        handleMultiSelect(selectedIds, true);
      }
      return;
    } else {
      if (handleMultiSelect) {
        handleMultiSelect([], false);
      }
      setSelected([]);
    }
  };

  
  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {;
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    if (handleSingleSelect) {
      handleSingleSelect(id, selectedIndex === -1);
    }
    if (handleDeleteMultiSelect) {
      handleDeleteMultiSelect(id, selectedIndex === -1);
    }
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if(window.location.href.indexOf('contractdetails') > 0 || window.location.href.indexOf('rras-view-email') > 0){
      //implement later for the grids in tabs 
    }
    else
    {
      localStorage.setItem(window.location + "RowsPerPage", event.target.value);
    }
  };
  const isSelected = (id: string) => selected.indexOf(id) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
 
  const visibleRows = React.useMemo( 
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        (page - 1) * perPage,
        page * perPage
      ), // eslint-disable-next-line react-hooks/exhaustive-deps
    [order, orderBy, page, rowsPerPage]
  );
  const handleClose = () => {
    setAnchorEl(null);
  };
  const moreHandleClick = 
    (index: number) => (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setCurrentIndex(index);
    };

  const handleOnDelete = (property: keyof Data<string>) => {
    setAnchorEl(null);
    var id = property;
    if (handleDelete) {
      handleDelete(id);
    }
  };

  const handleOnUnassign = (property: keyof Data<string>) => {
    setAnchorEl(null);
    var id = property;
    if (handleUnassign) {
      handleUnassign(id);
    }
  };

  const handleOnReanalyze = (property: keyof Data<string>) => {
    setAnchorEl(null);
    var id = property;
    if (handleReanalyze) {
      handleReanalyze(id);
    }
  };
  const handleEditAction = (property: keyof Data<string>) => {
    setAnchorEl(null);
    var id = property;
    if (handleEdit) {
      handleEdit(id);
    }
  };
  const handleViewAction = (property: keyof Data<string>) => {
    setAnchorEl(null);
    var id = property;
    if (handleView) {
      handleView(id);
    }
  };
  const handleMoveAction = (property: keyof Data<string>) => {
    setAnchorEl(null);
    var id = property;
    if (handleMove) {
      handleMove(id);
    }
  };
  const handleReprocessAction = (property: keyof Data<string>) => {
    setAnchorEl(null);
    var id = property;
    if (handleReprocess) {
      handleReprocess(id);
    }
  };
  const handleViewClick = (
    event: React.MouseEvent<unknown>,
    id: string,
    clickable?: any
  ) => {
    if (handleView) {
      handleView(id, clickable);
    }
  };

  const [userStatus, setUserStatus] = React.useState<undefined | boolean>(
    undefined
  );
  const handleToggle = (id: string, status: string) => {
    const newStatus =
      userStatus === undefined ? !getStatus(status) : !userStatus;
    setUserStatus(newStatus);
    if (handleToggleClick) {
      handleToggleClick(id, status);
    }
  };
  function getStatus(rowStatus: string) {
    if (rowStatus === "Active") {
      return true;
    } else if (rowStatus === "InActive" || rowStatus === "Locked") {
      return false;
    }
    return true; // Default to true if status is not recognized
  }

  const handleAttachContractAction = (property: keyof Data<string>) => {
    setAnchorEl(null);
    var id = property;
    if (handleAttachContracts) {
      handleAttachContracts(id);
    }
  };
  const getRowColor = (row) => {
    if (row["isPrecedence"] == "Y") return "row-grey";
    else return "";
  };
  const getCellColor = (cell, row) => {
    if (row === "Standard" && cell["id"].toUpperCase() === ColumnNameType)
      return "row-fore-red";
    if (row === "Custom" && cell["id"].toUpperCase() === ColumnNameType)
      return "row-fore-green";
    else return cell.className ?? "tableCell";
  };

  const extractTextFromReactElement = (element: React.ReactNode): string => {
    let text = '';
  
    const traverse = (node: React.ReactNode): void => {
      if (node === null || node === undefined) {
        return;
      } else if (typeof node === 'string' || typeof node === 'number') {
        text += node;
      } else if (Array.isArray(node)) {
        node.forEach(child => traverse(child));
      } else if (React.isValidElement(node)) {
        traverse(node.props.children);
      }
    };
  
    traverse(element);
    return text.trim();
  };

  return (
    // <Box sx={{ width: '100%' }}>
    <Paper sx={{ width: "100%", pt: 0 }} elevation={0}>
      <TableContainer className="tableContainerWrap">
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{ minWidth: 1000 }}
          aria-labelledby="tableTitle"
          size={"medium"}
          className={isDisable ? 'disabled-table' : ''}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            column={headCells}
            enableCheckBox={enableCheckBox}
            enableRadioButton={enableRadioButton}
            enableRadioButtonTemplate={enableRadioButtonTemplate}
          />
          <TableBody>
            {visibleRows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{ cursor: "pointer" }}
                  className={getRowColor(row)}
                >
                  {enableCheckBox && (
                    <TableCell className="tableCell" padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(e) => handleChecked(e, row.id)
                          }
                        disabled={AllowedRoleStatus.includes(extractTextFromReactElement(row.name)) ? true  : false} 
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                  )}
                   {enableRadioButton && (
                    <TableCell className="tableCell" padding="checkbox">
                      <Radio
                        color="primary"
                        name="enhancedTableRadioGroup"
                        value={row.meetingID} 
                        checked={effectiveSelectedRadio === row.meetingID}
                        onChange={(e) => {
                          handleRadioButtonSelect(e, row.meetingID);
                        }}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>
                  )}
                  {enableRadioButtonTemplate && (
                    <TableCell className="tableCell" padding="checkbox">
                      <Radio
                        color="primary"
                        name="enhancedTableRadioGroup"
                        value={row.id} 
                        checked={effectiveSelectedRadio === row.id}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleRadioButtonSelect(e, row.id);
                        }}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>
                  )}
                  {headCells.map((headCell) => {
                    if (headCell.visible === false) {
                      return null;
                    }
                    if(headCell.id === "promptText"){
                      return (
                        <TableCell
                          key={headCell.id}
                          className={headCell.className ?? "action-cell"}
                          title={extractTextFromReactElement(row[headCell.id])}
                          align={headCell.numeric ? "right" : "left"}
                          padding={headCell.disablePadding ? "none" : "normal"}
                        >
                          <IconButton
                            onClick={moreHandleClick(index)}
                            className=""
                          >
                          <VisibilityOutlined  onClick={() => handleViewAction(row.id)} className="actionMenuItemIcon" />
                          </IconButton>
                        </TableCell>
                      )}
                    if (headCell.id === "action") {
                      return (
                        <TableCell
                          key={headCell.id}
                          className="tooltip tooltiptext"
                          title={extractTextFromReactElement(row[headCell.id])}
                          align={headCell.numeric ? "right" : "left"}
                          padding={headCell.disablePadding ? "none" : "normal"}
                        >
                          <IconButton
                            onClick={moreHandleClick(index)}
                            className=""
                          >
                            <MoreVert />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={open && currentIndex === index}
                            onClose={handleClose}
                            className="contractListActionContainer"
                          >
                            {headCell.viewAction && (
                              <MenuItem
                                className="actionMenuItemWrap"
                                divider
                                onClick={() => handleViewAction(row.id)}
                                disabled={headCell.hasViewPermission === undefined ? false : !headCell.hasViewPermission} 
                              >
                                <VisibilityOutlined className="actionMenuItemIcon" />
                                View
                              </MenuItem>
                            )}
                            {headCell.userAction && (
                              <MenuItem
                                className="actionMenuItemWrap edit-icon"
                                divider
                                onClick={() => handleEditAction(row.id)}
                                disabled={headCell.hasEditPermission === undefined ? false : !headCell.hasEditPermission}
                              >
                                <EditNoteOutlined className="actionMenuItemIcon" />
                                Edit                                
                              </MenuItem>
                            )}
                            {headCell.editAction && (
                              <MenuItem
                                className="actionMenuItemWrap edit-icon"
                                divider
                                onClick={() => handleEditAction(row.id)}
                                disabled={headCell.hasEditPermission === undefined ? false : !headCell.hasEditPermission}
                              >
                                <EditNoteOutlined className="actionMenuItemIcon" />
                                Edit                                
                              </MenuItem>
                            )}
                            {headCell.attachContract && (
                              <MenuItem
                                className="actionMenuItemWrap edit-icon"
                                divider
                                onClick={() =>
                                  handleAttachContractAction(row.id)
                                }
                                disabled={headCell.hasAttachContractPermission === undefined ? false : !headCell.hasAttachContractPermission}
                              >
                                <AttachFileOutlined className="actionMenuItemIcon" />
                                Attach Contracts
                              </MenuItem>
                            )}
                            {headCell.moveAction && (
                              <MenuItem
                                className="actionMenuItemWrap"
                                divider
                                onClick={() => handleMoveAction(row.id)}                            
                                disabled={ headCell.hasMovePermission === undefined ? false 
                                    : !headCell.hasMovePermission ? true : 
                                    AllowedCompletedStatus.includes(extractTextFromReactElement(row.statusText)) ? false  : true
                                } 
                                >
                                <MoveUpOutlined className="actionMenuItemIcon" />
                              Move
                              </MenuItem>
                            )}
                            {headCell.reprocessAction && (
                              <MenuItem
                                className="actionMenuItemWrap"
                                divider
                                onClick={() => handleReprocessAction(row.id)}
                                disabled={headCell.hasRePreProcessPermission === undefined ? false : !headCell.hasRePreProcessPermission ? 
                                  true : AllowedReanalyzeStatus.includes(extractTextFromReactElement(row.statusText)) ? false  : true}  
                              >
                                <QueryStats className="actionMenuItemIcon" />
                                Reanalyze
                              </MenuItem>
                            )}
                            {headCell.deleteAction && (
                              <MenuItem
                                className="deleteIconWrap"
                                divider
                                onClick={() => handleOnDelete(row.id)}
                                disabled={headCell.hasDeletePermission === undefined ? false : !headCell.hasDeletePermission ? 
                                  true : AllowedRoleStatus.includes(extractTextFromReactElement(row.name)) ? true  : false
                                }                                
                              >
                                <DeleteOutlineOutlined className="actionMenuItemIcon" />
                                Delete
                              </MenuItem>
                            )}
                            {headCell.reanalyzeAction && (
                              <MenuItem
                                className="reanalyzeIconWrap"
                                divider
                                onClick={() => handleOnReanalyze(row.id)}
                                disabled={headCell.hasReanalyzeContractPermission === undefined ? false : !headCell.hasReanalyzeContractPermission ? 
                                  true : AllowedReanalyzeStatus.includes(extractTextFromReactElement(row.statusText)) ? false  : true
                                }
                                >
                                <QueryStats className="actionMenuItemIcon" />
                                <span className="actionMenuItemText">
                                  Reanalyze
                                </span>
                              </MenuItem>
                            )}
                            {headCell.statusAction && (
                              <h4 className="act-inct-title">User Status</h4>
                            )}
                            {headCell.statusAction && (
                              <MenuItem
                                onClick={() =>
                                  handleToggle(row.id, row["status"])
                                }
                                className="active-inactive-item"
                                disabled={headCell.hasActivateInactivateUnlockUserPermission === undefined ? false : !headCell.hasActivateInactivateUnlockUserPermission}
                              >
                                <span className="active-inact-label">
                                  {userStatus && row["status"] === "Active"
                                    ? "Active"
                                    : !userStatus &&
                                      row["status"] === "InActive"
                                    ? "InActive"
                                    : !userStatus && row["status"] === "Locked"
                                    ? "Locked"
                                    : "Active"}
                                </span>
                                <ListItemIcon>
                                  <Switch
                                    checked={
                                      userStatus === undefined
                                        ? getStatus(row["status"])
                                        : userStatus
                                    }
                                  />
                                </ListItemIcon>
                              </MenuItem>
                            )}
                            {headCell.unassignAction && (
                              <MenuItem
                                divider
                                onClick={() => handleOnUnassign(row.id)}
                                disabled={headCell.hasDeletePermission === undefined ? false : !headCell.hasDeletePermission}                                
                              >
                                <PersonOffIcon className="actionMenuItemIcon" />
                                Unassign User
                              </MenuItem>
                            )}
                          </Menu>
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        key={headCell.id}
                        //  className={headCell.className ?? "tableCell"}
                        className={getCellColor(headCell, row[headCell.id])}
                        title={
                          headCell.isDateField
                            ? UTCToLocalTimeZone(
                                row[headCell.id] === null
                                  ? ""
                                  : row[headCell.id]
                              )
                            : extractTextFromReactElement(row[headCell.id])
                        }
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        onClick={(event) => {
                          if (headCell.viewAction) {
                            handleViewClick(
                              event,
                              row.id,
                              row["isEditableUser"]
                                ? row["isEditableUser"]
                                : undefined
                            );
                          }
                        }}
                      >
                        {headCell.isDateField ? (
                          UTCToLocalTimeZone(
                            row[headCell.id] === null ? "" : row[headCell.id]
                          )
                        ) : headCell.isFieldWithImage ? (
                          <div className="user-info">
                            {
                              <Avatar
                                aria-label="Profile Image"
                                className="user-avatar"
                                src={row.imagePath ?? "/static/images/avatar/2.jpg"}
                              />
                            }
                            {/* <img src={headCell.id == "imagePath" ?  row[headCell.id]:"#"} alt="User Image" className="user-image" /> */}
                            <div className="username-email">
                              <div className="user-name">
                                {headCell.id === "displayName"
                                  ? row[headCell.id]
                                  : ""}
                              </div>
                              {/* <div className="user-email">{headCell.id == "email" ?  row[headCell.id]:"not available"}</div> */}
                              <div className="user-email">{row["email"]}</div>
                            </div>
                          </div>
                        ) : headCell.id === "hasLinkedContracts" &&
                          row[headCell.id].toString() === "true" ? (
                          <AttachFileOutlined />
                        ) : (
                          // Render other cell content
                          <span>{row[headCell.id]}</span>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {visibleRows.length === 0 && (
          <div className="emptyContractList">No Records Found</div>
        )}
      </TableContainer>
      <Pagination
        count={Math.ceil(rows.length / perPage)}
        page={page}
        onChange={handleChangePage}
        color="primary"
        sx={{ padding: '20px 0', display: 'flex', justifyContent: 'center' }}
      />
    </Paper>
  );
}