import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { getPortfolioCompanyNameById } from '../../networking/apiEndpoints';
const initialState: any = {
    portFolioCompanyModel: [],
    isLoadingDesignation:false,
    responseStatus: 200
};

export const getPortFolioCompanyById = createAsyncThunk(
  "portFolioCompanyModel/getPortFolioCompanyById",
  async (id:string, thunkApi) => { 

      try {
          var token = localStorage.getItem('token');
          const response = await axios.get(getPortfolioCompanyNameById + '/' + id, {
              headers: {"Content-Type": "application/json", Authorization:`Bearer ${token}`}
          });
          if (response.status === 401) {
              return thunkApi.rejectWithValue(response);
          }
          return response.data;
      } catch (err) {
          console.log(err);
      }
  }
);

export const getPortFolioCompanyByIdSlice = createSlice({
    name: "getportfoliocompanybyid",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPortFolioCompanyById.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(getPortFolioCompanyById.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
                if(action.payload !== undefined)
                    {
                      state.responseStatus = action.payload.status;
                    }
            })
            .addCase(getPortFolioCompanyById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? '';
                if(action.payload !== undefined)
                    {
                      state.responseStatus = 401;
                    }
            });
    },
});

export default getPortFolioCompanyByIdSlice.reducer;
