
import CryptoJS from 'crypto-js';
import { loginSecretKey } from '../networking/apiEndpoints';
import { getPermissionsFromToken } from './getPermissionsFromToken';
import { AddNewContractTypePermission, AddNewMeetingPermission, AddNewSummaryTemplatePermission, 
  AttachContractsPermission, CreateContractsTermsPermission, CreateCustomEmailTemplatePermission, 
  DeleteContractsPermission, DeleteContractsTermsPermission, DeleteContractTypePermission, 
  DeleteCustomEmailTemplatePermission, DeleteEmailAndSummaryPermission, 
  DeleteSummaryTemplatePermission, DownloadContractPermission, DownloadCSVReportPermission, 
  EditContractsTermsPermission, EditContractTypePermission, EditCustomEmailTemplatePermission, 
  EditEmailAndSummaryPermission, EditSummaryTemplatePermission, GenerateContractsReportPermission, 
  GenerateUsersFeedbackReportPermission, NavCSTContractsDashboard, NavCSTContractsTermsDashboard, 
  NavCSTContractsTypesDashboard, NavCSTGenerateContractsReport, NavCSTRolesPermissionDashboard, 
  NavCSTUserFeedbackReport, NavCSTUsersDashboard, NavREGEmailTemplateDashboard, NavREGMeetingDashboard, 
  NavREGRolesPermissionDashboard, NavREGSummaryTemplateDashboard, NavREGUserDashboard, 
  NavStaffingDashboard, NavStaffingEmployeeListDashboard, NavStaffingEditEmployee,
  NavStaffingDesignationListDashboard, NavStaffingAddDesignation, NavStaffingEditDesignation, NavStaffingDeleteDesignation, 
  NavStaffingBillingDesignationListDashboard, NavStaffingAddBillingDesignation, NavStaffingEditBillingDesignation, NavStaffingDeleteBillingDesignation, 
  NavStaffingPortfolioCompanyListDashboard, NavStaffingAddPortfolioCompany, NavStaffingEditPortfolioCompany, NavStaffingDeletePortfolioCompany, 
  NavStaffingEmployeeProjectListDashboard, NavStaffingAddEmployeeProject, NavStaffingEditEmployeeProject, NavStaffingDeleteEmployeeProject,
  NavStaffingUsersDashboard, NavStaffingRolesPermissionDashboard, ProvideFeedbackPermission, ReanalyzeContractsPermission, 
  RemoveLinkedContractsPermission, SetContractPrecedenceOrderPermission, UploadContractsPermission, 
  ViewContractsDashboardPermission, ViewContractsTermsDashboardPermission, ViewContractTypeDashboardPermission, 
  ViewEmailDashboardPermission, ViewEmailTemplatesDashboardPermission, ViewSummaryTemplateDashboardPermission, NAVCSTUserProfileInformationPermission, NAVREGUserProfileInformationPermission, EditMyProfilePermission, ViewMyProfilePermission, ChangePasswordPermission, 
  ViewSalesTrainingPromptsPermission,
  CreateSalesTrainingPromptPermission,
  EditSalesTrainingPromptPermission,
  DeleteSalesTrainingPromptPermission
} from './PermissionConstants';


export const encrypt = (text : string) => {
    const secretKey = loginSecretKey; 
    const key = CryptoJS.enc.Base64.parse(secretKey!); // Decode the Base64 key
    const iv = CryptoJS.enc.Hex.parse('00000000000000000000000000000000'); // Zero IV for simplicity
    const encrypted = CryptoJS.AES.encrypt(text, key, { iv: iv });
    return encrypted.toString();
   };

export const decrypt = (encryptedText : string) => {
    const secretKey = loginSecretKey; 
    const key = CryptoJS.enc.Base64.parse(secretKey!); // Decode the Base64 key
    const iv = CryptoJS.enc.Hex.parse('00000000000000000000000000000000'); // Zero IV for simplicity
    const decrypted = CryptoJS.AES.decrypt(encryptedText, key, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
   };
 
   export const getIsCST = (): boolean => {
    let cstArray: string[] = [];
    const userPermissions = getPermissionsFromToken();
    cstArray.push(NavCSTContractsDashboard);
    cstArray.push(NavCSTContractsTypesDashboard);
    cstArray.push(NavCSTContractsTermsDashboard);
    cstArray.push(NavCSTUserFeedbackReport);
    cstArray.push(NavCSTGenerateContractsReport);
    cstArray.push(NavCSTUsersDashboard);
    cstArray.push(NavCSTRolesPermissionDashboard);
    cstArray.push(AddNewContractTypePermission);
    cstArray.push(ViewContractTypeDashboardPermission);
    cstArray.push(EditContractTypePermission);
    cstArray.push(DeleteContractTypePermission);
    cstArray.push(CreateContractsTermsPermission);
    cstArray.push(ViewContractsTermsDashboardPermission);
    cstArray.push(EditContractsTermsPermission);
    cstArray.push(DeleteContractsTermsPermission);
    cstArray.push(GenerateContractsReportPermission);
    cstArray.push(GenerateUsersFeedbackReportPermission);
    cstArray.push(DownloadCSVReportPermission);
    cstArray.push(UploadContractsPermission);
    cstArray.push(ReanalyzeContractsPermission);
    cstArray.push(SetContractPrecedenceOrderPermission);
    cstArray.push(AttachContractsPermission);
    cstArray.push(RemoveLinkedContractsPermission);
    cstArray.push(ViewContractsDashboardPermission);
    cstArray.push(DeleteContractsPermission);
    cstArray.push(ProvideFeedbackPermission);
    cstArray.push(DownloadContractPermission);
    cstArray.push(NAVCSTUserProfileInformationPermission);
    return cstArray.some(item => userPermissions.includes(item));  
  }

  export const getIsRRAS = (): boolean => {
    let regArray: string[] = [];
    const userPermissions = getPermissionsFromToken();
    regArray.push(NavREGMeetingDashboard);
    regArray.push(NavREGSummaryTemplateDashboard);
    regArray.push(NavREGEmailTemplateDashboard);
    regArray.push(NavREGUserDashboard);
    regArray.push(NavREGRolesPermissionDashboard);
    regArray.push(AddNewMeetingPermission);
    regArray.push(ViewEmailDashboardPermission);
    regArray.push(EditEmailAndSummaryPermission);
    regArray.push(DeleteEmailAndSummaryPermission);
    regArray.push(AddNewSummaryTemplatePermission);
    regArray.push(ViewSummaryTemplateDashboardPermission);
    regArray.push(EditSummaryTemplatePermission);
    regArray.push(DeleteSummaryTemplatePermission);
    regArray.push(CreateCustomEmailTemplatePermission);
    regArray.push(ViewEmailTemplatesDashboardPermission);
    regArray.push(EditCustomEmailTemplatePermission);
    regArray.push(DeleteCustomEmailTemplatePermission);
    regArray.push(NAVREGUserProfileInformationPermission);
    regArray.push(ViewSalesTrainingPromptsPermission);
    regArray.push(CreateSalesTrainingPromptPermission);
    regArray.push(EditSalesTrainingPromptPermission);
    regArray.push(DeleteSalesTrainingPromptPermission);
    return regArray.some(item => userPermissions.includes(item)); 
  };   


  export const getIsSTAFFING = (): boolean => {
    let staffingArray: string[] = [];
    const userPermissions = getPermissionsFromToken();
    staffingArray.push(NavStaffingDashboard);
    staffingArray.push(NavStaffingEmployeeListDashboard);
    staffingArray.push(NavStaffingEditEmployee );
    staffingArray.push(NavStaffingDesignationListDashboard);
    staffingArray.push(NavStaffingAddDesignation);
    staffingArray.push(NavStaffingEditDesignation);
    staffingArray.push(NavStaffingDeleteDesignation);
    staffingArray.push(NavStaffingBillingDesignationListDashboard);
    staffingArray.push(NavStaffingAddBillingDesignation);
    staffingArray.push(NavStaffingEditBillingDesignation);
    staffingArray.push(NavStaffingDeleteBillingDesignation);
    staffingArray.push(NavStaffingPortfolioCompanyListDashboard);
    staffingArray.push(NavStaffingAddPortfolioCompany);
    staffingArray.push(NavStaffingEditPortfolioCompany);
    staffingArray.push(NavStaffingDeletePortfolioCompany);
    staffingArray.push(NavStaffingEmployeeProjectListDashboard);
    staffingArray.push(NavStaffingAddEmployeeProject);
    staffingArray.push(NavStaffingEditEmployeeProject);
    staffingArray.push(NavStaffingDeleteEmployeeProject);
    staffingArray.push(NavStaffingUsersDashboard);
    staffingArray.push(NavStaffingRolesPermissionDashboard);
    
    return staffingArray.some(item => userPermissions.includes(item)); 
  };   