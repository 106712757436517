import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { CircularProgress, circularProgressClasses, Divider } from "@mui/material";
import LeftNavListWrap from "./leftNavListWrap";
import { useLocation } from "react-router-dom";
import { useModuleSwitcher } from "../../redux/features/moduleSwitcher/moduleSwicherHook";
import { usePermissionNavList } from "./permissionNavHook";
import DialogWithTwoButton from "../../components/cst/DialogWithTwoButton";
import { useREGModuleMessage } from "../../redux/features/rras/regModuleMessage/regModuleMessageHook";
import { RegModuleMessage } from "../../utilities/Constants";
import { userInfoInterface } from "../../types/pages/userInfoType";
import { DaysToReDisplayDisclaimer } from "../../utilities/Constants";
import { updateUserDiscliamerLogs } from "../../redux/features/rras/updateUserDisclaimerLogsSlice";
import { addUserDiscliamerLogs } from "../../redux/features/rras/addUserDisclaimerLogsSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";

const drawerWidth = "100%";
const LeftNav = () => {
  const [selectedIndex, setSelectedIndex] = useState<string>(
    "ContractManagement_0"
  );
  const Dispatch = useAppDispatch();
  const[loading , setIsLoading] = useState(false);
  const tenantId = useAppSelector((state) => state.getAllTenant.selectedTenant);  
  const {mesageOpen, showREGModuleMessage, firstTimeOpen, closeREGModuleMessagePopup} = useREGModuleMessage();
  const {LeftNavListREG, LeftNavListCST, LeftNavListStaffing} = usePermissionNavList();
  const { activeTabIndex } = useModuleSwitcher();
  const [userInfo, setUserInfo] = useState<userInfoInterface | null>(null);
  const path = useLocation();
  const buildNumber = (window as any).applicationconfig?.BuildNumber || "N/A";
  const containRras = (path: any): boolean => {
    let rrasEnable = activeTabIndex;
    if (path.pathname.includes("/rras")) {
      return true;
    }
    else if (rrasEnable === 1) {
      return true;
    }else if (rrasEnable === 2) {
      return false;
    }
    else {
      return path.search.includes("rass");
    };
  };

  const containCst = (path: any): boolean => {
    let cstEnable = activeTabIndex;
    if (path.pathname.includes("/cst")) {
      return true;
    }
    else if (cstEnable === 0) {
      return true;
    }
    else {
      return false;
    };
  }; 
  
  function isDisclaimerExpired(modifiedOn: any): boolean {
    if (modifiedOn === null || modifiedOn === "") {
        return true;
    }
    const dateValue = new Date(modifiedOn);        
    if (isNaN(dateValue.getTime())) {
        return true;     }
    const currentDate = new Date();    
    dateValue.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    const diffTime = Math.abs(currentDate.getTime() - dateValue.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > Number(DaysToReDisplayDisclaimer);
}

  useEffect(() => {    
    const userInfoString = JSON.parse(
      localStorage.getItem("userInfo") ?? ""
    ) as userInfoInterface;       
       setUserInfo(userInfoString);    
    const isDisclaimerRequired = isDisclaimerExpired(userInfoString.UpdatedOnRegDisclaimer);    
    if(containRras(path) && isDisclaimerRequired){
      showREGModuleMessage();
    }
  },[path])

  const useInfo = JSON.parse(
    localStorage.getItem("userInfo") ?? JSON.stringify({ Name: "", Role: "", Permissions: "", DisplayName: "" })
  ) as userInfoInterface;
  const userRoles = useInfo?.Role;
 
const updateUserDisclaimerLogs = ()=>{  
  setIsLoading(true);    
  const userId = useInfo.Id??"";  
  const response = Dispatch(updateUserDiscliamerLogs(userId)).then(
    (response) => {               
      if(response.payload.status) 
      {
        const responseAddLogs = Dispatch(addUserDiscliamerLogs({userId : userId , tenantId: tenantId})).then(          
          (responseAddLogs) => {                           
            if(responseAddLogs.payload.status) 
            {
              if (userInfo) {
                const updatedUserInfo: userInfoInterface = {
                  ...userInfo,
                  UpdatedOnRegDisclaimer: new Date().toISOString(), // Set to current date/time
                };                          
                localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
                setUserInfo(updatedUserInfo); 
              }
              setIsLoading(false);
              closeREGModuleMessagePopup();        
            }            
          }
        );  
      }                  
    }    
  ); 
}
  const drawer = (
    <div className="leftNavContainer">
      <Toolbar className="logo">
        <img
          className="appLogoWrapper"
          src={`${process.env.PUBLIC_URL}/riverside-company-logo.svg`}
          alt="App Logo"
        />
      </Toolbar>
      <Divider />
        <LeftNavListWrap
          leftNavList={containRras(path) ? LeftNavListREG : (containCst(path) ? LeftNavListCST : LeftNavListStaffing)}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
        <div className="appVersion">
          Build# {buildNumber}
        </div>
    </div>
  );
  if (loading) {
    return (
      <div className="loader-page-center">
        <CircularProgress
          classes={{ root: circularProgressClasses.root }}
        ></CircularProgress>
      </div>
    );
  }
  return (
    <>     
    <Box sx={{ display: "flex" }}>
      <Drawer
        className="drawer-container"
        PaperProps={{
          elevation: 3,
        }}
        variant="permanent"
        sx={{
          "& .MuiDrawer-paper": { width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
    
    {mesageOpen && <DialogWithTwoButton
        dialogOpen={true}
        message={RegModuleMessage}
        yesBtnClick={updateUserDisclaimerLogs}
        dialogClose={() => {}}
        buttonOneText="OK"
        buttonOneType="outlined"
        isCloseShow = {false}
        className = {"reg-module-message-popup"}
      />}
    </>
    
  );
}
export default LeftNav;

